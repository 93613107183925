import React, { useEffect, useState } from "react";
import Styles from "./assets/Sidebar.module.css";
import Logo from "../assets/logo/logo.png";
import SidebarItems from "./SidebarItems";
import axiosInstance from "../axiosInterceptor";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

export default function Sidebar() {
  const [profileData, setProfileData] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("admin/getProfile")
      .then((response) => {
        if (response.data.isSuccess) {
          setProfileData(response.data?.profile?.pages);
        } else {
          toast.error(response.data.error);
        }
      })
      .catch((error) => {
        toast.error("Something went wrong. Please try again!!");
      });
  }, []);

  return (
    <>
      <div className={`${Styles.sidebar} bg-white`}>
        <div className={`${Styles.border} text-center p-3 `}>
          <Link to="/welcome">
            <img src={Logo} alt="DoubtQ" className="p-2" />
          </Link>
        </div>

        <li className="border-top list-unstyled" />
        <ul className={`${Styles.menu} col-12 p-3`}>
          {/* {profileData?.map((item) => {
            return (
              <SidebarItems key={item.pageId} submenu={item?.allocatedActions?.map((submenu) => {
                return (submenu.status === true && submenu.actionType !== 'List' )&& submenu.actionType
              })}>
                {item.pageName}
              </SidebarItems>

            )
          })} */}
          {profileData?.map((item) => {
            // Check if the item has status true
            if (
              item.allocatedActions.some((action) => action.status === true)
            ) {
              // Check if the current item is "Master Tables"
              if (item.pageName === "Master Tables") {
                return (
                  <SidebarItems
                    key={item.pageId}
                    submenu={[
                      "Category",
                      "Sub Category",
                      "Subject",
                      "Sub Subject",
                      "Font Size",
                      "Font Style",
                      "Reference Style",
                      "Line Spacing",
                      "Bonus Offer",
                      "Exam Prep Doc.",
                      // "Universities",
                      // "Courses",
                      "Course Faqs",
                      // "Course Inquiry",
                      "Complete Help Step",
                      "Banner",
                      "Bonus",
                      "Become an Expert",
                      "Benefits",
                      "Academic Help",
                      "Social Media",
                      "Reviews",
                      "Testimonials",
                      "Question Price",
                      "Live Session Price",
                      "Assignment Price",
                      "Posting Streak",
                      "Whatsapp Query",
                      "CMS",
                      "Email Settings",
                      // "Page",
                      // "Page Action",
                    ]}
                  >
                    {item.pageName}
                  </SidebarItems>
                );
              }

              // For other pages, render SidebarItems based on allocatedActions
              const subMenuItems = item.allocatedActions
                .filter(
                  (submenu) =>
                    submenu.status === true && submenu.actionType !== "List"
                )
                .map((submenu) => submenu.actionType);

              return (
                <SidebarItems
                  key={item.pageId}
                  submenu={subMenuItems.length > 0 ? subMenuItems : null}
                >
                  {item.pageName}
                </SidebarItems>
              );
            }

            return null; // If status is false, return null to skip rendering
          })}
        </ul>

        {/* <ul className={`${Styles.menu} col-12 p-3`}>
          <SidebarItems>Dashboard</SidebarItems>
          <SidebarItems>Students</SidebarItems>
          <SidebarItems>Orders</SidebarItems>

          <SidebarItems submenu={["Assignment Wallet", "Assignment List"]}>
            Assignment Help
          </SidebarItems>

          <SidebarItems submenu={["Live Session Wallet", "Live Session List"]}>
            Live Session Help
          </SidebarItems>

          <SidebarItems submenu={["Homework Wallet", "Homework List"]}>
            Homework Help
          </SidebarItems>

          <SidebarItems
            submenu={["Subscription Plan", "Library-Question List"]}
          >
            Library
          </SidebarItems>

          <SidebarItems>Rewards</SidebarItems>
          <SidebarItems>Promo Code</SidebarItems>
          <SidebarItems>Contact Us</SidebarItems>
          <SidebarItems>Settings</SidebarItems>
          <SidebarItems>Sitemap</SidebarItems>
          <SidebarItems>Users & Rights</SidebarItems>
          <SidebarItems
            submenu={[
              "Category",
              "Sub Category",
              "Subject",
              "Font Size",
              "Font Style",
              "Reference Style",
              "Line Spacing ",
              "Bonus Offer",
              "Complete Help Step",
              "Banner",
              "Bonus",
              "Become an Expert",
              "Benefits",
              "Academic Help",
              "Social Media",
              "Student Stories",
              "Question Price",
              "Live Session Price",
              "Assignment Price",
              "Posting Streak",
              "Whatsapp Query",
              "CMS",
              "Email Settings",
              "Page",
              "Page Action",
            ]}
          >
            Master Tables
          </SidebarItems>
        </ul> */}
      </div>
    </>
  );
}
