import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import delete1 from "../homework/assets/images/delete.png";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";
import { dateFormat } from "../../dateFormat";

const PageAction = () => {
  const [loading, setLoading] = useState(false);
  const [bannerData, setBannerData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigator = useNavigate();
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `admin/pageAction/listAllPageAction`,
        {
          perPage: perPage,
          pageNo: page,
        }
      );
      const { data, totalPages } = response.data;
      setBannerData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/pageAction/deletePageAction/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <div className="my-2">
          <div className="row mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="page-title">Page Action</h4>
              </div>
              <div>
                <Link to="/add-page-action">
                  <Button className="action-button add-from-new">
                    <img
                      src={pluscircle}
                      alt="plus"
                      width={20}
                      className="ADD_code"
                    />
                    <span className=""> Add </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              {loading ? (
                <div className="text-center mt-4">
                  <p>Loading...</p>
                </div>
              ) : bannerData?.length > 0 ? (
                <div className="table-responsive">
                  <Table bordered responsive className="mt-4 text-center" style={{ fontSize: '0.8rem' }}>
                    <thead>
                      <tr>
                        <th>Page name</th>
                        <th>Action Type</th>
                        <th>Updated At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {bannerData?.map((item) => {
                        return (
                          <tr>
                            <td>{item.pageId?.pageName}</td>
                            <td>{item.actionType}</td>
                            <td>{dateFormat(item.updatedAt)}</td>
                            <td className="d-flex justify-content-center">
                              <div className={`${Styles.edit_code}`}>
                                <Button
                                  className={`${Styles.edit_code}`}
                                  onClick={() => {
                                    navigator("/add-page-action", {
                                      state: item,
                                    });
                                  }}
                                >
                                  <img
                                    src={grp713}
                                    alt="Edit"
                                    className=""
                                    srcSet=""
                                    width={20}
                                  />
                                  Edit
                                </Button>
                              </div>
                              <div
                                className={`${Styles.delete_button_code} me-2 mx-2`}
                              >
                                <Button
                                  className={`${Styles.delete_button_code}`}
                                  onClick={() => handleDelete(item._id)}
                                >
                                  <img
                                    src={delete1}
                                    alt="Delete"
                                    className={`${Styles.delete_icon}`}
                                    srcSet=""
                                  />
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <p>No data found.</p>
                </div>
              )}
              <div className="float-end">
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>
                  {[...Array(totalPages)].map((_, i) => (
                    <PaginationItem key={i} active={i + 1 === currentPage}>
                      <PaginationLink onClick={() => handlePageClick(i + 1)}>
                        {i + 1}
                      </PaginationLink>
                    </PaginationItem>
                  ))}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this page?</ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PageAction;
