import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import pdf from "../../assets/icon/pdf.png";
import { ImageLink } from "../../constants";
import Select from "react-select";
import CommonEditor from "./CommonEditor";

const AddCourses = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [universityNames, setUniversityNames] = useState([]);
  const [fileError, setFileError] = useState(false);
  const [selectedTypes, setSelectedTypes] = useState([]);
  const navigator = useNavigate();

  const [data, setData] = useState({
    title: editData?.title || "",
    subTitle: editData?.subTitle || "",
    courseName: editData?.courseName || "",
    courseUrl: editData?.courseUrl || "",
    university: editData?.university?._id || "",
    views: editData?.views || "",
    purchases: editData?.purchases || "",
    studyNotes: {
      price: editData?.studyNotes?.price || "",
      filePath: editData?.studyNotes?.filePath || "",
    },
    practicePapers: {
      price: editData?.practicePapers?.price || "",
      filePath: editData?.practicePapers?.filePath || "",
    },
    questionBank: {
      price: editData?.questionBank?.price || "",
      filePath: editData?.questionBank?.filePath || "",
    },
    overview: editData?.overview || "",
    type: editData?.type || [],
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name.includes(".")) {
      const [parent, child] = name.split(".");
      setData((prev) => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value,
        },
      }));
    } else {
      setData((prev) => ({
        ...prev,
        [name]: value,
      }));
    }
  };

  const handleTypeChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setData({
      ...data,
      type: selectedValues,
    });
  };

  const handleFileChange = (e) => {
    const { name, files } = e.target;
    if (files.length > 0) {
      const filePath = URL.createObjectURL(files[0]);
      setData({
        ...data,
        [name]: {
          ...data[name],
          filePath: files[0],
        },
      });
      setFileError(false);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (
      !data.courseName ||
      !data.university ||
      !data.views ||
      !data.purchases ||
      data.type.length === 0 || // Ensure type is validated correctly
      !data.studyNotes.price ||
      !data.practicePapers.price ||
      !data.questionBank.price ||
      !data.overview
    ) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("subTitle", data.subTitle);
      formData.append("courseName", data.courseName);
      formData.append("courseUrl", data.courseUrl);
      formData.append("university", data.university);
      formData.append("views", data.views);
      formData.append("purchases", data.purchases);
      data.type.forEach((item, index) => {
        formData.append(`type[${index}]`, item);
      });
      formData.append("overview", data.overview);
      formData.append("studyNotesPrice", data.studyNotes.price);
      formData.append("practicePapersPrice", data.practicePapers.price);
      formData.append("questionBankPrice", data.questionBank.price);
      if (data.studyNotes.filePath instanceof File) {
        formData.append("studyNotes", data.studyNotes.filePath);
      }
      if (data.practicePapers.filePath instanceof File) {
        formData.append("practicePapers", data.practicePapers.filePath);
      }
      if (data.questionBank.filePath instanceof File) {
        formData.append("questionBank", data.questionBank.filePath);
      }

      const response = await axiosInstance.post(
        "admin/course/createCourse",
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/courses");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error.response.data.message ||
          "Something went wrong. Please try again!!"
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (
      !data.courseName ||
      !data.university ||
      !data.views ||
      !data.purchases ||
      data.type.length === 0 || // Ensure type is validated correctly
      !data.studyNotes.price ||
      !data.practicePapers.price ||
      !data.questionBank.price ||
      !data.overview
    ) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("title", data.title);
      formData.append("subTitle", data.subTitle);
      formData.append("courseName", data.courseName);
      formData.append("courseUrl", data.courseUrl);
      formData.append("university", data.university);
      formData.append("views", data.views);
      formData.append("purchases", data.purchases);
      data.type.forEach((item, index) => {
        formData.append(`type[${index}]`, item);
      });
      formData.append("overview", data.overview);
      formData.append("studyNotesPrice", data.studyNotes.price);
      formData.append("practicePapersPrice", data.practicePapers.price);
      formData.append("questionBankPrice", data.questionBank.price);
      if (data.studyNotes.filePath instanceof File) {
        formData.append("studyNotes", data.studyNotes.filePath);
      }
      if (data.practicePapers.filePath instanceof File) {
        formData.append("practicePapers", data.practicePapers.filePath);
      }
      if (data.questionBank.filePath instanceof File) {
        formData.append("questionBank", data.questionBank.filePath);
      }

      const response = await axiosInstance.put(
        `admin/course/updateCourse/${editData._id}`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/courses");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const fetchData = async (page = 1) => {
    try {
      const response = await axiosInstance.get(
        `admin/universities/getAllUniversity`
      );
      const { data } = response.data;
      setUniversityNames(data);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const Type = ["Special Study Notes", "Practice Papers", "Question Bank"];

  useEffect(() => {
    if (!data?.type || data?.type.length === 0) {
      setSelectedTypes(
        Type.map((item) => ({
          value: item,
          label: item,
        }))
      );
      setData((prev) => ({
        ...prev,
        type: Type,
      })); // Ensure data.type is set correctly
    } else {
      setSelectedTypes(
        data.type.map((item) => ({
          value: item,
          label: item,
        }))
      );
    }
  }, [data, Type]);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:
        formSubmitted && !data.university ? "red" : provided.borderColor,
      boxShadow: state.isFocused
        ? formSubmitted && !data.university
          ? "0 0 0 0.2rem rgba(255, 0, 0, 0.25)" // Red box shadow when invalid and focused
          : "none" // Blue box shadow on normal focus
        : provided.boxShadow,
      "&:hover": {
        borderColor:
          formSubmitted && !data.university
            ? "red"
            : provided["&:hover"].borderColor,
      },
    }),
  };

  const handleSelectChange = (selectedOption) => {
    setData({
      ...data,
      university: selectedOption ? selectedOption.value : "",
    });
  };

  const university = [];
  universityNames.map((item) =>
    item?.universityNames?.map((item) => university.push(item))
  );

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Course</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row mb-4">
                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="title" className="col-form-label">
                          Title
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="title"
                          placeholder="Enter your title"
                          name="title"
                          defaultValue={data.title}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="subTitle" className="col-form-label">
                          Subtitle
                        </Label>
                        <textarea
                          type="text"
                          className="form-control"
                          id="subTitle"
                          placeholder="Enter your subtitle"
                          name="subTitle"
                          defaultValue={data.subTitle}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="courseName" className="col-form-label">
                          Name
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="courseName"
                          placeholder="Enter your name"
                          name="courseName"
                          invalid={
                            !editData && !data.courseName && formSubmitted
                          }
                          defaultValue={data.courseName}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Name is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="courseUrl" className="col-form-label">
                          Course URL
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="courseUrl"
                          placeholder="Enter course URL"
                          name="courseUrl"
                          defaultValue={data.courseUrl}
                          onChange={(e) => handleChange(e)}
                        />
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="type" className="col-form-label">
                          Type
                        </Label>
                        <Select
                          id="type"
                          name="type"
                          isMulti
                          options={Type?.map((item) => ({
                            value: item,
                            label: item,
                          }))}
                          value={selectedTypes}
                          onChange={(selectedOptions) => {
                            setSelectedTypes(selectedOptions);
                            handleTypeChange(selectedOptions);
                          }}
                          isClearable
                          placeholder="--- Select Type ---"
                          styles={customStyles}
                          classNamePrefix="react-select"
                        />
                        {formSubmitted && !data.type && (
                          <FormFeedback style={{ display: "block" }}>
                            Type is required
                          </FormFeedback>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="university" className="col-form-label">
                          University
                        </Label>
                        <Select
                          id="university"
                          name="university"
                          value={universityNames
                            .map((item) => ({
                              value: item?._id,
                              label: item?.universityName || "No name",
                            }))
                            .find((option) => option.value === data.university)}
                          onChange={handleSelectChange}
                          options={universityNames.map((item) => ({
                            value: item._id,
                            label: item.universityName || "No name",
                          }))}
                          isClearable
                          placeholder="Select a university"
                          classNamePrefix="react-select"
                          styles={customStyles}
                          isSearchable
                        />
                        {formSubmitted && !data.university && (
                          <FormFeedback style={{ display: "block" }}>
                            University is required
                          </FormFeedback>
                        )}
                      </div>
                    </div>

                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="views" className="col-form-label">
                          Views
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="views"
                          placeholder="Enter number of views"
                          name="views"
                          invalid={!editData && !data.views && formSubmitted}
                          defaultValue={data.views}
                          onChange={(e) => handleChange(e)}
                          min={0}
                        />
                        <FormFeedback>Views are required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group mb-2">
                        <Label for="purchases" className="col-form-label">
                          Purchases
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="purchases"
                          placeholder="Enter number of purchases"
                          name="purchases"
                          invalid={
                            !editData && !data.purchases && formSubmitted
                          }
                          defaultValue={data.purchases}
                          onChange={(e) => handleChange(e)}
                          min={0}
                        />
                        <FormFeedback>Purchases are required</FormFeedback>
                      </div>
                    </div>

                    <div className="col-sm-4 mt-4">
                      <div className="form-group  mb-4">
                        <Label for="studyNotes" className="col-form-label">
                          Special Study Notes File
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="studyNotes"
                          name="studyNotes"
                          onChange={handleFileChange}
                        />
                        {editData?.studyNotes?.filePath && (
                          <div className="d-flex align-items-center mt-4">
                            <small className="text-muted me-2">
                              Current file:
                            </small>
                            <Link
                              to={ImageLink(editData?.studyNotes?.filePath)}
                              target="_blank"
                            >
                              <img src={pdf} alt="PDF" width={40} />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4 mt-4">
                      <div className="form-group  mb-4">
                        <Label for="practicePapers" className="col-form-label">
                          Practice Papers File
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="practicePapers"
                          name="practicePapers"
                          onChange={handleFileChange}
                        />
                        {editData?.practicePapers?.filePath && (
                          <div className="d-flex align-items-center mt-4">
                            <small className="text-muted me-2">
                              Current file:
                            </small>
                            <Link
                              to={ImageLink(editData?.practicePapers?.filePath)}
                              target="_blank"
                            >
                              <img src={pdf} alt="PDF" width={40} />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4 mt-4">
                      <div className="form-group  mb-4">
                        <Label for="questionBank" className="col-form-label">
                          Question Bank File
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="questionBank"
                          name="questionBank"
                          onChange={handleFileChange}
                        />
                        {editData?.questionBank?.filePath && (
                          <div className="d-flex align-items-center mt-4">
                            <small className="text-muted me-2">
                              Current file:
                            </small>
                            <Link
                              to={ImageLink(editData?.questionBank?.filePath)}
                              target="_blank"
                            >
                              <img src={pdf} alt="PDF" width={40} />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-2">
                        <Label for="studyNotesPrice" className="col-form-label">
                          Special Study Notes Price
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="studyNotesPrice"
                          placeholder="Enter price of special study notes"
                          name="studyNotes.price"
                          invalid={
                            !editData && !data.studyNotes.price && formSubmitted
                          }
                          value={data.studyNotes.price}
                          onChange={handleChange}
                          min={0}
                        />
                        <FormFeedback>Price is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-2">
                        <Label
                          for="practicePapersPrice"
                          className="col-form-label"
                        >
                          Practice Papers Price
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="practicePapersPrice"
                          placeholder="Enter price of practice papers"
                          name="practicePapers.price"
                          invalid={
                            !editData &&
                            !data.practicePapers.price &&
                            formSubmitted
                          }
                          value={data.practicePapers.price}
                          onChange={handleChange}
                          min={0}
                        />
                        <FormFeedback>Price is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-2">
                        <Label
                          for="questionBankPrice"
                          className="col-form-label"
                        >
                          Question Bank Price
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="questionBankPrice"
                          placeholder="Enter price of question bank"
                          name="questionBank.price"
                          invalid={
                            !editData &&
                            !data.questionBank.price &&
                            formSubmitted
                          }
                          value={data.questionBank.price}
                          onChange={handleChange}
                          min={0}
                        />
                        <FormFeedback>Price is required</FormFeedback>
                      </div>
                    </div>
                    {/* <div className="col-sm-6">
                      <div className="form-group  mb-4">
                        <Label
                          for="combinedPackage"
                          className="col-sm-4 col-form-label"
                        >
                          Combined Package File
                        </Label>

                        <Input
                          type="file"
                          className="form-control"
                          id="combinedPackage"
                          name="combinedPackage"
                          invalid={fileError && formSubmitted}
                          onChange={handleFileChange}
                        />
                        <FormFeedback>
                          {editData?._id
                            ? "Please select a file to update"
                            : "Document file is required"}
                        </FormFeedback>
                        {editData?.combinedPackage && (
                          <div className="d-flex align-items-center mt-4">
                            <small className="text-muted me-2">
                              Current file:
                            </small>
                            <Link
                              to={ImageLink(editData?.combinedPackage)}
                              target="_blank"
                            >
                              <img src={pdf} alt="PDF" width={40} />
                            </Link>
                          </div>
                        )}
                      </div>
                    </div> */}
                    <div className="col-sm-12">
                      <div className="form-group mb-2">
                        <Label for="overview" className="col-form-label">
                          Overview
                        </Label>
                        {/* <textarea
                          type="text"
                          className={`form-control ${!data.overview && formSubmitted ? "is-invalid" : ""
                            }`}
                          id="overview"
                          placeholder="Enter course overview"
                          name="overview"
                          defaultValue={data.overview}
                          onChange={(e) => handleChange(e)}
                        />
                        {!data.overview && formSubmitted && (
                          <FormFeedback>Overview is required</FormFeedback>
                        )} */}
                        <CommonEditor
                          value={data.overview}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "overview", value },
                            })
                          }
                        />
                        {formSubmitted && !data?.overview?.trim() && (
                          <div className="text-danger">
                            Overview is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/courses">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddCourses;
