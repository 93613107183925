import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";
import CommonEditor from "./CommonEditor";

const Setting = () => {
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [settingData, setSettingData] = useState([]);
  const [data, setData] = useState({
    bannerImage: "",
    completeHelpImage: "",
    downloadAppsImage: "",
    certificateImage: "",
    shortDescription: "",
    academicHelp: "",
    disclaimer: "",
    promoCodeLabel: "",
    tutorCounter: "",
    studentCounter: "",
    questionsCounter: "",
    referralPercentage: "",
    discountPercentage: "",
    contactNumber: "",
    contactEmail: "",
    showDowloadAppsImage: false,
    showBonusOffer: false,
    paymentMethod: [],
  });

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axiosInstance.get(`admin/setting/getSetting`);
      const { data } = response.data;
      setSettingData(data);
      setData({ ...data });
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleChange = (e) => {
    const { name, value, checked, files, type } = e.target;

    if (
      name === "bannerImage" ||
      name === "completeHelpImage" ||
      name === "downloadAppsImage" ||
      name === "certificateImage"
    ) {
      if (files && files.length > 0) {
        const reader = new FileReader();
        const file = files[0];

        reader.onloadend = () => {
          setData({
            ...data,
            [name]: file,
            [`${name}Preview`]: reader.result,
          });
        };

        reader.readAsDataURL(file);
      }
    } else if (name === "showDowloadAppsImage" || name === "showBonusOffer") {
      setData({ ...data, [name]: checked });
    } else {
      setData({ ...data, [name]: value });
    }
    if (name === "paymentMethod") {
      let updatedPaymentMethod;
      if (type === "checkbox") {
        updatedPaymentMethod = checked
          ? [...data.paymentMethod, value]
          : data.paymentMethod.filter((method) => method !== value);
      } else {
        updatedPaymentMethod = value;
      }
      setData({ ...data, [name]: updatedPaymentMethod });
    } else {
      setData({ ...data, [name]: value });
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    if (data.contactNumber.length > 11 || data.contactNumber.length < 9) {
      return toast.error("contact number must be 10 digit");
    }
    const formattedContactNumber = `${data.contactNumber}`;

    try {
      const formData = new FormData();
      if (data.bannerImage instanceof File) {
        formData.append("bannerImage", data.bannerImage);
      }
      if (data.completeHelpImage instanceof File) {
        formData.append("completeHelpImage", data.completeHelpImage);
      }
      if (data.downloadAppsImage instanceof File) {
        formData.append("downloadAppsImage", data.downloadAppsImage);
      }
      if (data.certificateImage instanceof File) {
        formData.append("certificateImage", data.certificateImage);
      }
      formData.append("shortDescription", data.shortDescription);
      formData.append("academicHelp", data.academicHelp);
      formData.append("disclaimer", data.disclaimer);
      formData.append("tutorCounter", data.tutorCounter);
      formData.append("studentCounter", data.studentCounter);
      formData.append("questionsCounter", data.questionsCounter);
      formData.append("discountPercentage", data.discountPercentage);
      formData.append("referralPercentage", data.referralPercentage);
      formData.append("promoCodeLabel", data.promoCodeLabel);
      formData.append("contactNumber", formattedContactNumber);
      formData.append("contactEmail", data.contactEmail);
      formData.append("showDowloadAppsImage", data.showDowloadAppsImage);
      formData.append("showBonusOffer", data.showBonusOffer);
      data.paymentMethod.forEach((method, index) => {
        formData.append(`paymentMethod[${index}]`, method);
      });

      const response = await axiosInstance.put(
        `admin/setting/updateSetting`,
        formData
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="my-2">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Settings</h4>
          </div>
        </div>
        <Card>
          <CardBody>
            <Row>
              <Col md="12">
                <form className="ps-5 pe-5 pt-3" onSubmit={handleUpdate}>
                  <div className="row">
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Label for="inputPassword" className="col-form-label">
                          Offer Banner Image
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="inputPassword"
                          name="bannerImage"
                          invalid={
                            !settingData && !data.bannerImage && formSubmitted
                          }
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Offer Banner image is required
                        </FormFeedback>
                        <div className="mt-4">
                          {data.bannerImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.bannerImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          ) : (
                            <img
                              src={ImageLink(settingData?.bannerImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Label for="inputPassword" className="col-form-label">
                          Complete Help Image
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="inputPassword"
                          name="completeHelpImage"
                          invalid={
                            !settingData &&
                            !data.completeHelpImage &&
                            formSubmitted
                          }
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Complete help image is required
                        </FormFeedback>
                        <div className="mt-4">
                          {data.completeHelpImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.completeHelpImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          ) : (
                            <img
                              src={ImageLink(settingData?.completeHelpImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Label for="inputPassword" className="col-form-label">
                          Download Apps Image
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="inputPassword"
                          name="downloadAppsImage"
                          invalid={
                            !settingData &&
                            !data.downloadAppsImage &&
                            formSubmitted
                          }
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Download app image is required
                        </FormFeedback>
                        <div className="mt-4">
                          {data.downloadAppsImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.downloadAppsImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          ) : (
                            <img
                              src={ImageLink(settingData?.downloadAppsImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-6">
                      <div className="form-group">
                        <Label
                          for="certificateImage"
                          className="col-form-label"
                        >
                          Course Certificate Image
                        </Label>
                        <Input
                          type="file"
                          className="form-control"
                          id="certificateImage"
                          name="certificateImage"
                          invalid={
                            !settingData &&
                            !data.certificateImage &&
                            formSubmitted
                          }
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          certificate image is required
                        </FormFeedback>
                        <div className="mt-4">
                          {data.certificateImage instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.certificateImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          ) : ( 

                            <img
                              src={ImageLink(settingData?.certificateImage)}
                              alt="Preview"
                              className="w-75"
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Short Description
                        </Label>
                        <CommonEditor
                          value={data.shortDescription}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "shortDescription", value },
                            })
                          }
                        />
                        {formSubmitted && !data?.shortDescription?.trim() && (
                          <div className="text-danger">
                            Description is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Long Description
                        </Label>
                        <CommonEditor
                          value={data.academicHelp}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "academicHelp", value },
                            })
                          }
                        />
                        {formSubmitted && !data?.academicHelp?.trim() && (
                          <div className="text-danger">
                            Description is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Disclaimer
                        </Label>
                        <CommonEditor
                          value={data.disclaimer}
                          placeholder="Enter content ..."
                          onChange={(value) =>
                            handleChange({
                              target: { name: "disclaimer", value },
                            })
                          }
                        />
                        {formSubmitted && !data?.disclaimer?.trim() && (
                          <div className="text-danger">
                            Disclaimer is required
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Tutor Counter
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          name="tutorCounter"
                          placeholder="Enter your tutor counter"
                          invalid={
                            !settingData && !data.tutorCounter && formSubmitted
                          }
                          value={data.tutorCounter}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Tutor counter is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Student Counter
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          name="studentCounter"
                          placeholder="Enter your student counter"
                          invalid={
                            !settingData &&
                            !data.studentCounter &&
                            formSubmitted
                          }
                          value={data.studentCounter}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Student counter is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Questions Solved Counter
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          name="questionsCounter"
                          placeholder="Enter your question counter"
                          invalid={
                            !settingData &&
                            !data.questionsCounter &&
                            formSubmitted
                          }
                          value={data.questionsCounter}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Question solved counter is required
                        </FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Promo Code Offer Lable
                        </Label>
                        <Input
                          type="text"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your promoCodeLabel"
                          name="promoCodeLabel"
                          invalid={
                            !settingData &&
                            !data.promoCodeLabel &&
                            formSubmitted
                          }
                          value={data.promoCodeLabel}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Promo Code offer label is required
                        </FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Referral Percentage
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          name="referralPercentage"
                          placeholder="Enter your referral %"
                          invalid={
                            !settingData &&
                            !data.referralPercentage &&
                            formSubmitted
                          }
                          value={data.referralPercentage}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Referral percentage is required
                        </FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-4">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Discount Percentage
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          name="discountPercentage"
                          placeholder="Enter your discount %"
                          invalid={
                            !settingData &&
                            !data.discountPercentage &&
                            formSubmitted
                          }
                          value={data.discountPercentage}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>
                          Discount percentage is required
                        </FormFeedback>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-3">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Contact Number
                        </Label>
                        <Input
                          type="number"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your contact number"
                          name="contactNumber"
                          invalid={
                            !settingData && !data.contactNumber && formSubmitted
                          }
                          value={data.contactNumber}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Contact number is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Contact email
                        </Label>
                        <Input
                          type="email"
                          className="form-control"
                          id="inputPassword"
                          placeholder="Enter your contact email"
                          name="contactEmail"
                          invalid={
                            !settingData && !data.contactEmail && formSubmitted
                          }
                          value={data.contactEmail}
                          onChange={(e) => handleChange(e)}
                        />
                        <FormFeedback>Contact email is required</FormFeedback>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Show Dowload Apps Image
                        </Label>
                        <FormGroup switch className="mt-2">
                          <Input
                            type="switch"
                            className={`${Styles.form_check_input}`}
                            name="showDowloadAppsImage"
                            checked={data.showDowloadAppsImage}
                            onChange={(e) => handleChange(e)}
                          />
                          <Label check className="mx-1">
                            {data.showDowloadAppsImage === true
                              ? "Active"
                              : "Deactive"}
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                    <div className="col-sm-3">
                      <div className="form-group mb-4">
                        <Label for="inputPassword" className="col-form-label">
                          Show Bonus Offer
                        </Label>
                        <FormGroup switch className="mt-2">
                          <Input
                            type="switch"
                            className={`${Styles.form_check_input}`}
                            name="showBonusOffer"
                            checked={data.showBonusOffer}
                            onChange={(e) => handleChange(e)}
                          />
                          <Label check className="mx-1">
                            {data.showBonusOffer === true
                              ? "Active"
                              : "Deactive"}
                          </Label>
                        </FormGroup>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <Label for="paymentMethod" className="col-form-label">
                          Payment Method
                        </Label>
                        <div className="d-flex">
                          <div className="form-check me-4">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="paymentMethodStripe"
                              value="Stripe"
                              name="paymentMethod"
                              onChange={handleChange}
                              checked={data?.paymentMethod?.includes("Stripe")}
                            />
                            <Label
                              for="paymentMethodStripe"
                              className="form-check-label"
                            >
                              Stripe
                            </Label>
                          </div>
                          <div className="form-check">
                            <Input
                              type="checkbox"
                              className="form-check-input"
                              id="paymentMethodRazorpay"
                              value="Razorpay"
                              name="paymentMethod"
                              onChange={handleChange}
                              checked={data?.paymentMethod?.includes("Razorpay")}
                            />
                            <Label
                              for="paymentMethodRazorpay"
                              className="form-check-label"
                            >
                              Razorpay
                            </Label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="form-group mb-4">
                        <div className={`${Styles.submit_button}`}>
                          <Button type="submit">Save</Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Setting;
