import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import pdf from "../../assets/icon/pdf.png";
import { ImageLink } from "../../constants";

const AddExamPrepDoc = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [fileError, setFileError] = useState(false);

  const [data, setData] = useState({
    documentName: editData?.documentName || "",
    documentFile: editData?.documentFile || "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name) {
      setData({ ...data, [name]: value });
      if (name === "documentName") setTitleError(false);
    }
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setData({ ...data, documentFile: file });
      setFileError(false);
    }
  };

  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!data.documentName || !data.documentFile) {
      setTitleError(!data.documentName);
      setFileError(!data.documentFile);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("documentName", data.documentName);
      formData.append("documentFile", data.documentFile);

      const response = await axiosInstance.post(
        `admin/freeDocuments/createFreeDocument`,
        formData
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/exam-prep-doc");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Something went wrong. Please try again!!"
      );
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    if (!data.documentName) {
      setTitleError(true);
      return;
    }

    try {
      const formData = new FormData();
      formData.append("documentName", data.documentName);

      if (data.documentFile instanceof File) {
        formData.append("documentFile", data.documentFile);
      }

      const response = await axiosInstance.put(
        `admin/freeDocuments/updateFreeDocument/${editData?._id}`,
        formData
      );

      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/exam-prep-doc");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message ||
          "Something went wrong. Please try again!!"
      );
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">
              {editData?._id ? "Edit Document" : "Add Document"}
            </h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="form-group row mb-4">
                    <Label
                      for="documentName"
                      className="col-sm-4 col-form-label"
                    >
                      Document Name
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="text"
                        className="form-control"
                        id="documentName"
                        placeholder="Enter document name"
                        name="documentName"
                        invalid={titleError && formSubmitted}
                        value={data.documentName}
                        onChange={handleChange}
                      />
                      <FormFeedback>Document name is required</FormFeedback>
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="documentFile"
                      className="col-sm-4 col-form-label"
                    >
                      Document File
                    </Label>
                    <div className="col-sm-8">
                      <Input
                        type="file"
                        className="form-control"
                        id="documentFile"
                        name="documentFile"
                        invalid={fileError && formSubmitted}
                        onChange={handleFileChange}
                      />
                      <FormFeedback>
                        {editData?._id
                          ? "Please select a file to update"
                          : "Document file is required"}
                      </FormFeedback>
                      {editData?.documentFile && (
                        <div className="d-flex align-items-center mt-4">
                          <small className="text-muted me-2">
                            Current file:
                          </small>
                          <Link
                            to={ImageLink(editData?.documentFile)}
                            target="_blank"
                          >
                            <img src={pdf} alt="PDF" width={40} />
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/exam-prep-doc">
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddExamPrepDoc;
