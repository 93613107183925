import React, { useEffect, useRef, useState } from "react";
import Styles from "../homework/assets/Form.module.css";

import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
// import ExportFile from "./Helper";
import ForStudent from "./ForStudent";
import PaymentUpdates from "./PaymentUpdates";
import CommonEditor from "../masters/CommonEditor";

const Form = () => {
  const location = useLocation();
  const [editData, setEditData] = useState(location.state);
  const formRef = useRef(null);
  // const adminId = localStorage.getItem("_id");
  const [isLoading, setIsLoading] = useState(false);
  const [estimatedPriceData, setEstimatedPriceData] = useState();
  const [deadlineData, setDeadLineData] = useState([]);
  const [estimatedPrice, setEstimatedPrice] = useState(
    editData?.estimatedPrice || 0
  );
  const [discountPrice, setDiscountPrice] = useState(
    editData?.discountPrice || 0
  );
  const [promoPrice, setPromoPrice] = useState(editData?.promoPrice || 0);
  const [totalPrice, setTotalPrice] = useState(0);
  // const [paymentStatus, setPaymentStatus] = useState([]);
  // const [editingEnabled, setEditingEnabled] = useState(false);
  const [question, setQuestion] = useState(editData?.question || "");
  const [questionStatus, setQuestionStatus] = useState(
    editData?.questionStatus || ""
  );
  const [refStyle, setRefStyle] = useState([]);
  const [selectedRefStyle, setSelectedRefStyle] = useState(
    editData?.selectReferenceStyle?.selectReferenceStyle || ""
  );
  const [lineSpacing, setLineSpacing] = useState([]);
  const [selectedLineSpacing, setSelectedsetLineSpacing] = useState(
    editData?.selectLineSpacing?.selectLineSpacing || ""
  );
  const [subjects, setSubjects] = useState([]);
  const [selectedSubject, setSelectedSubject] = useState(
    editData?.subjectId?._id || ""
  );
  const [fontStyle, setFontStyle] = useState([]);
  const [selectedFontStyle, setSelectedFontStyle] = useState(
    editData?.selectFontStyle?.selectFontStyle || ""
  );
  const [fontSize, setFontSize] = useState([]);
  const [selectedFontSize, setSelectedFontSize] = useState(
    editData?.selectFontSize?.selectFontSize || ""
  );

  const [numerOfWords, setNumerOfWords] = useState(editData?.numerOfWords || 0);
  const [deadlineHours, setDeadlineHours] = useState(
    editData?.deadlineHours || ""
  );
  const [remark, setRemark] = useState(editData?.remark || "");
  const [studentPaymentStatus, setStudentPayStatus] = useState(
    editData?.studentPaymentStatus || ""
  );
  const [paymentMethod, setPaymentMethod] = useState(
    editData?.paymentMethod || ""
  );
  const [studentPaymentId, setStudentPaymentId] = useState(
    editData?.paymentId || ""
  );

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        // const response = await axiosInstance.get(`admin/subject/getAllSubject`);
        const response = await axiosInstance.get(`admin/subSubject/getAllSubSubject`);
        if (response.data.isSuccess) {
          setSubjects(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchSubjects();
  }, []);

  // Define fetchEstimatedPrice function
  const fetchEstimatedPrice = async () => {
    if (
      numerOfWords &&
      deadlineHours &&
      editData?.subCategoryId?._id !== "6586d9a9dafb1f4a8f461274" &&
      editData?.subCategoryId?._id !== "6586dfe7d2f7eeadeea4fafa"
    ) {
      try {
        const response = await axiosInstance.post(
          "/admin/assignmentPrice/getPriceByWords",
          {
            subCategoryId: editData?.subCategoryId?._id,
            words: numerOfWords,
            hours: deadlineHours,
          }
        );
        setEstimatedPriceData(response.data.price);
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  useEffect(() => {
    fetchEstimatedPrice();
  }, [numerOfWords, deadlineHours]);

  const fetchWordshr = async () => {
    if (
      numerOfWords &&
      deadlineHours &&
      editData?.subCategoryId?._id !== "6586d9a9dafb1f4a8f461274" &&
      editData?.subCategoryId?._id !== "6586dfe7d2f7eeadeea4fafa"
    ) {
      try {
        const response = await axiosInstance.post(
          "/admin/assignmentPrice/getHoursByWords",
          {
            subCategoryId: editData?.subCategoryId?._id,
            words: numerOfWords,
          }
        );
        setDeadLineData(response.data.hours);
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  useEffect(() => {
    fetchWordshr();
  }, []);

  useEffect(() => {
    const fetchrefStyle = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/refStyle/getAllRefStyle`
        );
        if (response.data.isSuccess) {
          setRefStyle(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchrefStyle();
  }, []);

  useEffect(() => {
    const fetchlineSpacing = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/lineSpacing/getAllLineSpacing`
        );
        if (response.data.isSuccess) {
          setLineSpacing(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchlineSpacing();
  }, []);

  useEffect(() => {
    const fetchfontStyle = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/fontStyle/getAllFontStyle`
        );
        if (response.data.isSuccess) {
          setFontStyle(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchfontStyle();
  }, []);

  useEffect(() => {
    const fetchfontSize = async () => {
      try {
        const response = await axiosInstance.get(
          `admin/fontSize/getAllFontSize`
        );
        if (response.data.isSuccess) {
          setFontSize(response.data.data);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    };

    fetchfontSize();
  }, []);

  const handleSubmitQuestion = async (event) => {
    event.preventDefault();

    const formData = new FormData();
    if (editData?.question) {
      formData.append("question", question);
    }
    if (formRef.current?.assignment_file_question?.files) {
      for (
        let i = 0;
        i < formRef.current.assignment_file_question.files.length;
        i++
      ) {
        formData.append(
          "questionPDF",
          formRef.current.assignment_file_question.files[i]
        );
      }
    }
    for (
      let i = 0;
      i < formRef.current.assignment_file_reference.files.length;
      i++
    ) {
      formData.append(
        "referencePDF",
        formRef.current.assignment_file_reference.files[i]
      );
    }

    if (editData?.selectedRefStyle) {
      formData.append("selectReferenceStyle", selectedRefStyle);
    }
    if (editData?.selectedLineSpacing) {
      formData.append("selectLineSpacing", selectedLineSpacing);
    }
    if (editData?.selectedSubject) {
      formData.append("subjectId", selectedSubject);
    }
    if (editData?.selectedFontStyle) {
      formData.append("selectFontStyle", selectedFontStyle);
    }
    if (editData?.selectedFontSize) {
      formData.append("selectFontSize", selectedFontSize);
    }
    formData.append("numerOfWords", numerOfWords);
    if (editData?.deadlineHours) {
      formData.append("deadlineHours", deadlineHours);
    }
    if (editData?.remark) {
      formData.append("remark", remark);
    }
    if (editData?.studentPaymentStatus) {
      formData.append("studentPaymentStatus", studentPaymentStatus);
    }
    if (editData?.paymentMethod) {
      formData.append("paymentMethod", paymentMethod);
    }
    formData.append("paymentId", studentPaymentId);
    if (editData?.questionStatus) {
      formData.append("questionStatus", editData.questionStatus);
    }
    formData.append("discountPrice", editData?.discountPrice?.toFixed(2));
    let estimatedPriceValue;
    if (numerOfWords && deadlineHours) {
      estimatedPriceValue =
        estimatedPriceData?.estimatedPrice ||
        editData?.estimatedPrice?.toFixed(2);
    } else {
      estimatedPriceValue = editData?.estimatedPrice?.toFixed(2);
    }
    formData.append("estimatedPrice", estimatedPriceValue);

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `admin/assignmentHelp/updateQuestion/${editData._id}`,
        formData
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
        resetForm();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  const initialQuestionFiles = editData
    ? editData.questionPDF.map((file) => file.split("/").pop())
    : [];
  const initialReferenceFiles = editData
    ? editData.referencePDF.map((file) => file.split("/").pop())
    : [];
  const baseURL = process.env.REACT_APP_FILE_BASE_URL;

  const [selectedQuestionFiles, setSelectedQuestionFiles] =
    useState(initialQuestionFiles);
  const [selectedReferenceFiles, setSelectedReferenceFiles] = useState(
    initialReferenceFiles
  );

  const handleQuestionFileChange = (event) => {
    const files = event.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setSelectedQuestionFiles([...selectedQuestionFiles, ...fileNames]);
  };

  const handleReferenceFileChange = (event) => {
    const files = event.target.files;
    const fileNames = Array.from(files).map((file) => file.name);
    setSelectedReferenceFiles([...selectedReferenceFiles, ...fileNames]);
  };

  const handleRemoveQuestionFile = async (index) => {
    const fileToRemove = editData?.questionPDF[index];
    const newFiles = selectedQuestionFiles.filter((_, i) => i !== index);
    newFiles.splice(index, 1);
    setSelectedQuestionFiles(newFiles);
    const requestData = {
      paymentSection: editData?.paymentSection,
      fieldName: "questionPDF",
      questionId: editData?._id,
      fileName: fileToRemove,
    };

    try {
      const response = await axiosInstance.post(
        "admin/deleteFile",
        requestData
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleRemoveReferenceFile = async (index) => {
    const fileToRemove = editData?.referencePDF[index];
    const newFiles = selectedReferenceFiles.filter((_, i) => i !== index);
    newFiles.splice(index, 1);
    setSelectedReferenceFiles(newFiles);
    const requestData = {
      paymentSection: editData?.paymentSection,
      fieldName: "referencePDF",
      questionId: editData?._id,
      fileName: fileToRemove,
    };

    try {
      const response = await axiosInstance.post(
        "admin/deleteFile",
        requestData
      );
      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    calculateTotalPrice();
  }, [estimatedPrice, discountPrice, promoPrice]);

  const handleEstimatedPriceChange = (value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      estimatedPrice: parseFloat(value),
    }));
    setEstimatedPrice(parseFloat(value) || 0);
    calculateTotalPrice();
  };

  const handleDiscountPriceChange = (value) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      discountPrice: parseFloat(value),
    }));
    setDiscountPrice(parseFloat(value) || 0);
    calculateTotalPrice();
  };

  const calculateTotalPrice = () => {
    const total = (estimatedPrice - discountPrice - promoPrice).toFixed(2);
    setTotalPrice(total);
  };

  const resetForm = () => {
    setQuestion("");
    setQuestionStatus("");
    setSelectedSubject("");
  };

  const handleRadioClick = (status) => {
    setEditData((prevEditData) => ({
      ...prevEditData,
      questionStatus: status,
    }));
  };


  const getCurrentDateTime = () => {
    const now = new Date();

    // Get current date components
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0"); // Month is zero-indexed
    const day = String(now.getDate()).padStart(2, "0");

    // Get current time components
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");
    const seconds = String(now.getSeconds()).padStart(2, "0");

    // Combine date and time components into the desired format
    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;

    return formattedDateTime;
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // Update remaining time for editData
      if (editData) {
        const dateTime1 = getCurrentDateTime();
        const inputDate = new Date(editData.deadlineDate);
        const dateTime2 = inputDate.toISOString().slice(0, 19);
        if (dateTime2 > dateTime1) {
          const updatedData = {
            ...editData,
            remainingTime: calculateRemainingTime(
              new Date(editData.deadlineDate).getTime()
            ),
          };
          setEditData(updatedData);
        } else {
          const updatedData = {
            ...editData,
            remainingTime: {
              hours: 0,
              minutes: 0,
              seconds: 0,
            },
          };
          setEditData(updatedData);
        }
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [editData]);

  const formatTime = (time) => {
    return time < 10 ? "0" + time : time;
  };

  const calculateRemainingTime = (deadlineDate) => {
    const now = new Date().getTime();
    const difference = deadlineDate - now;
    const hours = Math.floor(difference / (1000 * 60 * 60));
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((difference % (1000 * 60)) / 1000);
    return {
      hours,
      minutes,
      seconds,
    };
  };

  return (
    <>
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className={`${Styles.order_text}`}>
            <p className={`${Styles.order_id}`}>
              Order ID: {editData?.displayId}
            </p>
            <p className={`${Styles.assignment_help_text}`}>
              Assignment Help - {editData?.subCategoryId?.name}
            </p>
          </div>
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex ps-5 pt-5 pb-1 pe-5">
                <p className={`${Styles.time_stamp_heading}`}>Time Stamp</p>
                <p className={`ps-3 ${Styles.date_and_time}`}>
                  {dateFormat(editData?.updatedAt)}
                </p>
              </div>
              <div className="ps-5 pt-5 pb-4 pe-5">
                {editData &&
                  editData?.studentPaymentStatus !== "Refund" &&
                  editData?.questionStatus !== "Solved" &&
                  editData.remainingTime?.hours !== 0 &&
                  editData.remainingTime?.minutes !== 0 &&
                  editData.remainingTime?.seconds !== 0 && (
                    <div className="d-flex justify-content-around">
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">
                          {formatTime(editData?.remainingTime?.hours)}
                        </p>
                        <p className={`${Styles.timer}`}>HOURS</p>
                      </div>
                      <div className={`${Styles.border} ms-5 me-5`}>
                        <p className="pt-2">
                          {formatTime(editData?.remainingTime?.minutes)}
                        </p>
                        <p className={`${Styles.timer}`}>MINUTES</p>
                      </div>
                      <div className={`${Styles.border}`}>
                        <p className="pt-2">
                          {formatTime(editData?.remainingTime?.seconds)}
                        </p>
                        <p className={`${Styles.timer}`}>SECONDS</p>
                      </div>
                    </div>
                  )}

                {editData &&
                  (editData.questionStatus === "Solved" ||
                    (editData.remainingTime?.hours <= 0 &&
                      editData.remainingTime?.minutes <= 0 &&
                      editData.remainingTime?.seconds <= 0)) && (
                    <div className={`${Styles.deadline} ps-5`}>
                      {editData.questionStatus === "Solved"
                        ? "Deadline Over"
                        : "Deadline Over"}
                    </div>
                  )}
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between ">
              <div className="ps-5 pt-2">
                <span className={`${Styles.student_corner_text}`}>
                  Student Corner
                </span>
              </div>
            </div>
            <form
              ref={formRef}
              className="pt-3"
              onSubmit={handleSubmitQuestion}
            >
              <div className="ps-5 pe-5">
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Email ID<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="emailid"
                      placeholder=""
                      defaultValue={editData?.studentId?.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Question<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-9">
                    <CommonEditor
                      value={question}
                      placeholder="Enter content ..."
                      onChange={setQuestion}
                      defaultValue={editData?.question}
                      readOnly={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                      className={`${Styles.h_200}`}
                    />
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label className="col-sm-3 col-form-label">
                    Assignment File<b className="ms-2">:</b>
                  </Label>
                  <div
                    className="col-sm-9"
                    style={{
                      backgroundColor: "#F5F5F5",
                      height: "auto",
                      padding: "0px",
                    }}
                  >
                    <Input
                      type="file"
                      name="assignment_file_question"
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                      multiple
                      onChange={handleQuestionFileChange}
                    />
                    <div className="d-flex align-items-center mt-4">
                      {selectedQuestionFiles?.length > 0 && (
                        <ol>
                          {selectedQuestionFiles.map((file, index) => (
                            <li className="mb-2 answer-pdf" key={index}>
                              <Link
                                className="text-decoration-none"
                                to={`${baseURL}${editData?.questionPDF[index]}`}
                                target="_blank"
                              >
                                {file}
                              </Link>
                              <button
                                className="mx-2 answer-pdf-box"
                                onClick={() => handleRemoveQuestionFile(index)}
                                disabled={
                                  editData?.studentPaymentStatus ===
                                    "Completed" ||
                                  editData?.studentPaymentStatus === "Refund"
                                }
                              >
                                X
                              </button>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  </div>
                </div>

                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Reference File<b className="ms-2">:</b>{" "}
                  </Label>
                  <div
                    className="col-sm-9"
                    style={{
                      backgroundColor: "#F5F5F5",
                      height: "auto",
                      padding: "0px",
                    }}
                  >
                    <Input
                      type="file"
                      name="assignment_file_reference"
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                      multiple
                      onChange={handleReferenceFileChange}
                    />
                    <div className="d-flex align-items-center mt-4">
                      {selectedReferenceFiles?.length > 0 && (
                        <ol>
                          {selectedReferenceFiles.map((file, index) => (
                            <li className="mb-2 answer-pdf" key={index}>
                              <Link
                                className="text-decoration-none"
                                to={`${baseURL}${editData?.referencePDF[index]}`}
                                target="_blank"
                              >
                                {file}
                              </Link>
                              <button
                                className="mx-2 answer-pdf-box"
                                onClick={() => handleRemoveReferenceFile(index)}
                                disabled={
                                  editData?.studentPaymentStatus ===
                                    "Completed" ||
                                  editData?.studentPaymentStatus === "Refund"
                                }
                              >
                                X
                              </button>
                            </li>
                          ))}
                        </ol>
                      )}
                    </div>
                  </div>
                </div>
                <div className="form-group row mb-4 mt-5 pt-3">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Subject<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="subjectId"
                      name="subjectId"
                      type="select"
                      onChange={(e) => setSelectedSubject(e.target.value)}
                      value={selectedSubject}
                      disabled
                    >
                      <option value="">
                        {editData
                          ? editData?.subjectId
                            ? editData?.subjectId?.questionSubject
                            : "Please select subject"
                          : "Please select subject"}
                      </option>
                      {subjects?.map((subject) => (
                        <option key={subject._id} value={subject._id}>
                          {subject?.subSubjectName}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Reference Style<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="reference"
                      name="reference"
                      type="select"
                      onChange={(e) => setSelectedRefStyle(e.target.value)}
                      value={selectedRefStyle}
                      disabled
                    >
                      <option value="" disabled>
                        {editData?.isReferenceStyle
                          ? `Yes | ${editData?.selectReferenceStyle?.refStyle}`
                          : "No"}
                      </option>
                      {refStyle.map((style) => (
                        <option key={style.id} value={style._id}>
                          {style.refStyle}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Line Spacing<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="linespacing"
                      name="linespacing"
                      type="select"
                      onChange={(e) =>
                        setSelectedsetLineSpacing(e.target.value)
                      }
                      value={selectedLineSpacing}
                      disabled
                    >
                      <option value="" disabled>
                        {editData?.isLineSpacing
                          ? `Yes | ${editData?.selectLineSpacing?.lineSpacing}`
                          : "No"}
                      </option>
                      {lineSpacing.map((line) => (
                        <option key={line.id} value={line._id}>
                          {line.lineSpacing}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Font Style<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="fontStyle"
                      name="fontStyle"
                      type="select"
                      onChange={(e) => setSelectedFontStyle(e.target.value)}
                      value={selectedFontStyle}
                      disabled
                    >
                      <option value="" disabled>
                        {editData?.isLineSpacing
                          ? `Yes | ${editData?.selectFontStyle?.fontStyle}`
                          : "No"}
                      </option>
                      {fontStyle.map((font) => (
                        <option key={font.id} value={font._id}>
                          {font.fontStyle}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Font Size <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="fontSize"
                      name="fontSize"
                      type="select"
                      onChange={(e) => setSelectedFontSize(e.target.value)}
                      value={selectedFontSize}
                      disabled
                    >
                      <option value="" disabled>
                        {editData?.isFontSize
                          ? `Yes | ${editData?.selectFontSize?.fontSize}`
                          : "No"}
                      </option>
                      {fontSize.map((size) => (
                        <option key={size.id} value={size._id}>
                          {size.fontSize}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    {editData?.subCategoryId?._id ===
                    "6586d937d2f7eeadeea4faf5" ? (
                      <span>
                        No of Pages<b className="ms-2">:</b>
                      </span>
                    ) : editData?.subCategoryId?._id ===
                      "6586d98fdafb1f4a8f46126f" ? (
                      <span>
                        No of Slides<b className="ms-2">:</b>
                      </span>
                    ) : (
                      <span>
                        No of Words<b className="ms-2">:</b>
                      </span>
                    )}
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="numerOfWords"
                      placeholder=""
                      onChange={(e) => setNumerOfWords(e.target.value)}
                      defaultValue={numerOfWords}
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Deadline (hrs)<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    {editData?.subCategoryId?._id ===
                      "6586dfe7d2f7eeadeea4fafa" ||
                    editData?.subCategoryId?._id ===
                      "6586d9a9dafb1f4a8f461274" ? (
                      // Render text input
                      <Input
                        type="text"
                        className="form-control"
                        id="deadlineHours"
                        placeholder=""
                        onChange={(e) => setDeadlineHours(e.target.value)}
                        defaultValue={deadlineHours}
                        disabled={
                          editData?.studentPaymentStatus === "Completed" ||
                          editData?.studentPaymentStatus === "Refund"
                        }
                      />
                    ) : (
                      // Render select input
                      <Input
                        type="select"
                        className="form-control"
                        id="deadlineHours"
                        value={deadlineHours}
                        onChange={(e) => setDeadlineHours(e.target.value)}
                        disabled={
                          editData?.studentPaymentStatus === "Completed" ||
                          editData?.studentPaymentStatus === "Refund"
                        }
                      >
                        {deadlineData.map((hour) => (
                          <option key={hour} value={hour}>
                            {hour} hours
                          </option>
                        ))}
                      </Input>
                    )}
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Remark<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <textarea
                      type="text"
                      className="form-control"
                      id="remark"
                      placeholder=""
                      onChange={(e) => setRemark(e.target.value)}
                      defaultValue={editData?.remark}
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Estimated Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    {editData?.subCategoryId?._id ===
                      "6586dfe7d2f7eeadeea4fafa" ||
                    editData?.subCategoryId?._id ===
                      "6586d9a9dafb1f4a8f461274" ? (
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="estimatedPrice"
                        placeholder=""
                        defaultValue={editData?.estimatedPrice?.toFixed(2)}
                        onChange={(e) =>
                          handleEstimatedPriceChange(e.target.value)
                        }
                        disabled={
                          editData?.studentPaymentStatus === "Paid" ||
                          editData?.studentPaymentStatus === "Completed" ||
                          editData?.studentPaymentStatus === "Refund" ||
                          (editData?.subCategoryId?._id !==
                            "6586d9a9dafb1f4a8f461274" &&
                            editData?.subCategoryId?._id !==
                              "6586dfe7d2f7eeadeea4fafa")
                        }
                      />
                    ) : (
                      // <span className={`${Styles.plandoll}`}>$</span>
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        name="estimatedPrice"
                        placeholder=""
                        value={estimatedPriceData?.estimatedPrice?.toFixed(2)}
                        onChange={(e) =>
                          handleEstimatedPriceChange(e.target.value)
                        }
                        disabled={
                          editData?.studentPaymentStatus === "Paid" ||
                          editData?.studentPaymentStatus === "Completed" ||
                          editData?.studentPaymentStatus === "Refund" ||
                          (editData?.subCategoryId?._id !==
                            "6586d9a9dafb1f4a8f461274" &&
                            editData?.subCategoryId?._id !==
                              "6586dfe7d2f7eeadeea4fafa")
                        }
                      />
                    )}
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Discounted Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="discountPrice"
                      placeholder=""
                      defaultValue={editData?.discountPrice?.toFixed(2)}
                      onChange={(e) =>
                        handleDiscountPriceChange(e.target.value)
                      }
                      disabled={
                        editData?.studentPaymentStatus === "Paid" ||
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Promocode <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.promoCode}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Total Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      value={totalPrice}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Status<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="studentPaymentStatus"
                      placeholder=""
                      onChange={(e) => setStudentPayStatus(e.target.value)}
                      defaultValue={studentPaymentStatus}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                    >
                      <option value="Paid">Paid</option>
                      <option value="Unpaid">Unpaid</option>
                      <option value="Refund">Refund</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Method<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="paymentMethod"
                      placeholder=""
                      onChange={(e) => setPaymentMethod(e.target.value)}
                      defaultValue={paymentMethod}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Paid" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                    >
                      <option value="Stripe">Stripe</option>
                      <option value="Razorpay">Razorpay</option>
                      <option value="Other">Other</option>
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    htmlFor="paymentId"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Id <b className="ms-2">:</b>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="paymentId"
                      defaultValue={editData?.paymentId}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Unpaid" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                      onChange={(e) => setStudentPaymentId(e.target.value)}
                    />
                  </div>
                </div>
                {editData && editData?.studentPaymentStatus === "Paid" && (
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-3 col-form-label"
                    >
                      Answering Status<b className="ms-2">:</b>
                    </Label>
                    <div className="col-sm-9">
                      <div className="radio-btn mt-2">
                        <Row>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Working")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Working"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Working</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Unsolved")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Unsolved"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Not Working</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Solved")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Solved"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Solved</Label>
                            </FormGroup>
                          </Col>
                          <Col md="3">
                            <FormGroup>
                              <Input
                                name="questionStatus"
                                type="radio"
                                onClick={() => handleRadioClick("Close")}
                                checked={
                                  editData &&
                                  editData.questionStatus === "Close"
                                }
                                disabled={
                                  editData?.studentPaymentStatus === "Refund" ||
                                  editData?.studentPaymentStatus === "Completed"
                                }
                              />
                              <Label className="mx-2">Close</Label>
                            </FormGroup>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              <div
                className={`d-flex justify-content-around ${Styles.quesion_color} p-3 mt-4`}
              >
                <div className="d-flex">
                  <div className={`${Styles.cancel_button} me-4`}>
                    <Button
                      type="button"
                      className={`${Styles.cancel_button}`}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={`${Styles.submit_button}`}>
                    <Button
                      type="submit"
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            <PaymentUpdates />
            <ForStudent />
          </div>
        </div>
      </div>
    </>
  );
};

export default Form;
