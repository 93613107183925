import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";
import Select from "react-select"; // Import react-select
import CommonEditor from "./CommonEditor";

const AddSubSubject = () => {
  const location = useLocation();
  const subjectData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();
  const [data, setData] = useState({
    subSubjectName: subjectData?.subSubjectName || "",
    image: subjectData?.image || "",
    subjectId: subjectData?.subjectId || "",
    subSubjectUrl: subjectData?.subSubjectUrl || "",
    description: subjectData?.description || "",
    isActive: subjectData?.isActive ?? true,
  });
  const [subjects, setSubjects] = useState([]);

  useEffect(() => {
    const fetchSubjects = async () => {
      try {
        const response = await axiosInstance.get("admin/subject/getAllSubject");
        if (response.status === 200) {
          const subjectsData = Array.isArray(response.data.data)
            ? response.data.data
            : [];
          setSubjects(subjectsData);
        } else {
          toast.error("Failed to fetch subjects");
        }
      } catch (error) {
        console.error("Error fetching subjects:", error);
        toast.error("Something went wrong. Please try again!");
        setSubjects([]);
      }
    };

    fetchSubjects();
  }, []);

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor:
        formSubmitted && !data.subjectId ? "red" : provided.borderColor,
      boxShadow: state.isFocused
        ? formSubmitted && !data.subjectId
          ? "0 0 0 0.2rem rgba(255, 0, 0, 0.25)"
          : "none"
        : provided.boxShadow,
      "&:hover": {
        borderColor:
          formSubmitted && !data.subjectId
            ? "red"
            : provided["&:hover"].borderColor,
      },
    }),
    // Add styles for the single value (selected option)
    singleValue: (provided) => ({
      ...provided,
      color: "#000000", // or any dark color that matches your theme
    }),
    // Style the options in the menu
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#000000" : "#000000",
      backgroundColor: state.isSelected
        ? "#0052cc"
        : state.isFocused
          ? "#deebff"
          : null,
    }),
    // Style the input text
    input: (provided) => ({
      ...provided,
      color: "#000000",
    }),
  };

  const handleChange = (e) => {
    const { name, files, type, checked } = e.target;
    if (name === "image") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (type === "checkbox" || type === "switch") {
      setData({ ...data, [name]: checked });
    } else {
      setData({
        ...data,
        [name]: e.target.value,
      });
    }
  };

  const handleSelectChange = (selectedOption) => {
    setData({
      ...data,
      subjectId: selectedOption ? selectedOption.value : "",
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.subSubjectName || !data.image || !data.subjectId) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("subSubjectName", data.subSubjectName);
      formData.append("image", data.image);
      formData.append("subjectId", data.subjectId);
      formData.append("description", data.description);
      formData.append("subSubjectUrl", data.subSubjectUrl);
      formData.append("isActive", data.isActive);
      const response = await axiosInstance.post(
        "admin/subSubject/createSubSubject",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/sub-subject");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.subSubjectName || !data.subjectId) {
      return;
    }

    try {
      const formData = new FormData();
      formData.append("subSubjectName", data.subSubjectName);
      formData.append("subjectId", data.subjectId);
      formData.append("subSubjectUrl", data.subSubjectUrl);
      formData.append("description", data.description);
      formData.append("isActive", data.isActive);

      // Only append image if it's a new File object
      if (data.image instanceof File) {
        formData.append("image", data.image);
      }

      const response = await axiosInstance.put(
        `admin/subSubject/updateSubSubject/${subjectData?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/sub-subject");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Sub Subject</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={subjectData?._id ? handleUpdate : handleSubmit}
                >
                  {/* Select main subject */}
                  <div className="form-group row">
                    <div className="col-sm-4">
                      <Label for="subjectId" className="col-form-label">
                        Main Subject
                      </Label>
                      <Select
                        id="subjectId"
                        name="subjectId"
                        value={subjects
                          ?.map((subject) => ({
                            value: subject._id,
                            label: subject.questionSubject || "No name",
                          }))
                          .find((option) => option.value === data.subjectId)}
                        onChange={handleSelectChange}
                        options={subjects?.map((subject) => ({
                          value: subject._id,
                          label: subject.questionSubject || "No name",
                        }))}
                        isClearable
                        placeholder="Select a Main Subject"
                        classNamePrefix="react-select"
                        styles={customStyles}
                        isSearchable
                      />
                      {formSubmitted && !data.subjectId && (
                        <FormFeedback className="d-block">
                          Main Subject is required
                        </FormFeedback>
                      )}
                    </div>
                    <div className="col-sm-4">
                      <Label for="subSubjectName" className="col-form-label">
                        Sub Subject Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="subSubjectName"
                        placeholder="Enter your sub subject name"
                        invalid={
                          !subjectData && !data.subSubjectName && formSubmitted
                        }
                        name="subSubjectName"
                        value={data.subSubjectName}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Sub subject name is required</FormFeedback>
                    </div>
                    <div className="col-sm-4">
                      <Label for="subSubjectUrl" className="col-form-label">
                        Sub Subject URL
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="subSubjectUrl"
                        placeholder="Enter your sub subject URL"
                        invalid={
                          !subjectData && !data.subSubjectUrl && formSubmitted
                        }
                        name="subSubjectUrl"
                        value={data.subSubjectUrl}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-4">
                      <Label for="image" className="col-form-label">
                        Image
                      </Label>
                      <Input
                        type="file"
                        id="image"
                        name="image"
                        invalid={!subjectData && !data.image && formSubmitted}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Subject image is required</FormFeedback>
                      {data.image && (
                        <div className="border w-100 h-75 text-center mt-2">
                          {data.image instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.image)}
                              alt="Preview"
                              className="mt-4"
                              style={{ width: "25%" }}
                            />
                          ) : (
                            <img
                              src={ImageLink(data.image)}
                              alt="Preview"
                              className="mt-4"
                              style={{ width: "25%" }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-8 mb-4">
                      <Label for="description" className="col-form-label">
                        Description
                      </Label>
                      <CommonEditor
                        value={data.description}
                        placeholder="Enter content ..."
                        onChange={(value) =>
                          handleChange({
                            target: { name: "description", value },
                          })
                        }
                      />
                    </div>
                  </div>
                  <br />
                  <div className="form-group row mb-4 mt-4">
                    <Label for="isActive" className="col-sm-4 col-form-label">
                      isActive
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          id="isActive"
                          name="isActive"
                          checked={data.isActive}
                          onChange={handleChange}
                          className={`${Styles.form_check_input}`}
                        />
                        <Label check className="mx-1">
                          {data.isActive ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  {/* Submit and Cancel Buttons */}
                  <div className="form-group row mb-4 mt-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {subjectData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/sub-subject">
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>

              {/* Image Preview */}
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddSubSubject;
