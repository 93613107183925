import React, { useState } from "react";
import Styles from "../homework/assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import CancelModal from "../CancelModal";
// import CoursePayment from "./CoursePayment";
import pdf from "../../assets/icon/pdf.png";
import CourseAdmin from "./CourseAdmin";
import { ImageLink } from "../../constants";

export default function CourseForm() {
  const location = useLocation();
  const editData = location.state;
  const [isLoading, setIsLoading] = useState(false);

  const [formState, setFormState] = useState({
    editingEnabled: false,
    anseditingEnabled: false,
    payeditingEnabled: false,
    courseId: editData?._id || "",
    studentPaymentStatus: editData?.studentPaymentStatus || "",
    paymentId: editData?.paymentId || "",
    paymentMethod: editData?.paymentMethod || "",
    step1: editData?.step1 || false,
    step2: editData?.step2 || false,
    step3: editData?.step3 || false,
    step4: editData?.step4 || false,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const response = await axiosInstance.put(
        `admin/purchaseCourse/updateCourse/${editData._id}`,
        {
          studentPaymentStatus: formState.studentPaymentStatus,
          paymentId: formState.paymentId,
          paymentMethod: formState.paymentMethod,
          step1: formState?.step1,
          step2: formState?.step2,
          step3: formState?.step3,
          step4: formState?.step4,
        }
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  const handlePaymentStatusChange = (e) => {
    const { name, value } = e.target;
    setFormState((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  const handleResume = () => {
    setShowModal(false);
  };

  const handleClose = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className={`${Styles.second_container}`}>
        <div className="d-flex justify-content-between">
          <div className={`${Styles.order_text}`}>
            <p className={`${Styles.order_id}`}>
              Order ID: {editData?.displayId}
            </p>
            <p className={`${Styles.assignment_help_text}`}>
              Course Help - {editData?.courseInnerType}
            </p>
          </div>
        </div>
        <div className={`bg-white ${Styles.assignment_content}`}>
          <div>
            <div className="d-flex justify-content-between ">
              <div className="d-flex ps-5 pt-5 pb-1 pe-5">
                <p className={`${Styles.time_stamp_heading}`}>Time Stamp</p>
                <p className={`ps-3 ${Styles.date_and_time}`}>
                  {dateFormat(editData?.updatedAt)}
                </p>
              </div>
            </div>
            <hr />
            <div className="d-flex justify-content-between ">
              <div className="ps-5 pt-2">
                <span className={`${Styles.student_corner_text}`}>
                  Student Corner
                </span>
              </div>
            </div>
            <form className="pt-3" onSubmit={handleSubmit}>
              <div className="ps-5 pe-5">
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Email ID<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="emailid"
                      placeholder=""
                      defaultValue={editData?.studentId?.email}
                      disabled
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Course Name<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="courseName"
                      placeholder=""
                      defaultValue={editData?.courseId?.courseName}
                      disabled
                    />
                  </div>
                </div>
                {editData?.courseFile && (
                  <div className="form-group row mb-4 mt-5 pt-3">
                    <Label className="col-sm-3 col-form-label">
                      Course File<b className="ms-2">:</b>
                    </Label>
                    <div className="col-sm-6">
                      <Link
                        to={ImageLink(editData?.courseFile)}
                        target="_blank"
                      >
                        <img src={pdf} alt="PDF" width={40} />
                      </Link>
                    </div>
                  </div>
                )}
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Course Type<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="text"
                      className="form-control"
                      id="courseInnerType"
                      placeholder=""
                      defaultValue={editData?.courseInnerType}
                      disabled
                    />
                  </div>
                </div>
                {/* <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Course Type<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      id="exampleSelect"
                      name="select"
                      type="select"
                      onChange={handleQuestionTypeChange}
                      value={formState.selectedQuestionType}
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    >
                      <option value="">
                        {editData
                          ? editData?.subCategoryId
                            ? editData?.subCategoryId.name
                            : "Please select type"
                          : "Please select type"}
                      </option>
                      {formState.questionTypes?.map((questionType) => (
                        <option key={questionType._id} value={questionType._id}>
                          {questionType.name}
                        </option>
                      ))}
                    </Input>
                  </div>
                </div> */}
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Status<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      value={formState?.studentPaymentStatus}
                      onChange={handlePaymentStatusChange}
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                      name="studentPaymentStatus"
                    >
                      <option value="Paid">Paid</option>
                      <option value="Completed">Completed</option>
                      {/* <option value="Refund">Refund</option> */}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Method<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6">
                    <Input
                      type="select"
                      className="form-control"
                      id="paymentMethod"
                      placeholder=""
                      value={formState.paymentMethod}
                      onChange={handlePaymentStatusChange}
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                      name="paymentMethod"
                    >
                      <option value="Stripe">Stripe</option>
                      <option value="Razorpay">Razorpay</option>
                      {/* <option value="Other">Other</option> */}
                    </Input>
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <label
                    htmlFor="paymentId"
                    className="col-sm-3 col-form-label"
                  >
                    Payment Id <b className="ms-2">:</b>
                  </label>
                  <div className="col-sm-6">
                    <input
                      type="text"
                      className="form-control"
                      id="paymentId"
                      defaultValue={editData?.paymentId}
                      disabled={
                        editData?.studentPaymentStatus === "Refund" ||
                        editData?.studentPaymentStatus === "Unpaid" ||
                        editData?.studentPaymentStatus === "Completed"
                      }
                      onChange={(e) =>
                        setFormState({
                          ...formState,
                          paymentId: e.target.value,
                        })
                      }
                    />
                  </div>
                </div>
                <div className="form-group row mb-4">
                  <Label
                    for="inputPassword"
                    className="col-sm-3 col-form-label"
                  >
                    Total Price<b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <span className={`${Styles.plandoll}`}>$</span>
                    <Input
                      type="text"
                      className="form-control"
                      id="inputPassword"
                      placeholder=""
                      defaultValue={editData?.totalPrice?.toFixed(2)}
                      disabled
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label for="step1" className="col-sm-3 col-form-label">
                    Step 1 <small>(Expert Compilation)</small>
                    <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className=""
                      id="step1"
                      name="step1"
                      placeholder=""
                      checked={formState.step1}
                      onChange={(e) =>
                        setFormState({ ...formState, step1: e.target.checked })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label for="step2" className="col-sm-3 col-form-label">
                    Step 2 <small>(Content Formatting)</small>
                    <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className=""
                      id="step2"
                      name="step2"
                      placeholder=""
                      checked={formState.step2}
                      onChange={(e) =>
                        setFormState({ ...formState, step2: e.target.checked })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label for="step3" className="col-sm-3 col-form-label">
                    Step 3 <small>(Thorough Review)</small>
                    <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className=""
                      id="step3"
                      name="step3"
                      placeholder=""
                      checked={formState.step3}
                      onChange={(e) =>
                        setFormState({ ...formState, step3: e.target.checked })
                      }
                    />
                  </div>
                </div>

                <div className="form-group row mb-4">
                  <Label for="step4" className="col-sm-3 col-form-label">
                    Step 4 <small>(Final Delivery)</small>
                    <b className="ms-2">:</b>
                  </Label>
                  <div className="col-sm-6 d-flex align-items-center">
                    <Input
                      type="checkbox"
                      className=""
                      id="step4"
                      name="step4"
                      placeholder=""
                      checked={formState.step4}
                      onChange={(e) =>
                        setFormState({ ...formState, step4: e.target.checked })
                      }
                    />
                  </div>
                </div>
              </div>
              <div
                className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
              >
                <div className="d-flex">
                  <div className={`${Styles.cancel_button} me-4`}>
                    <Button
                      type="button"
                      className={`${Styles.cancel_button}`}
                      onClick={() =>
                        setFormState((prevState) => ({
                          ...prevState,
                          editingEnabled: false,
                        }))
                      }
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    >
                      Cancel
                    </Button>
                  </div>
                  <div className={`${Styles.submit_button}`}>
                    <Button
                      type="submit"
                      disabled={
                        editData?.studentPaymentStatus === "Completed" ||
                        editData?.studentPaymentStatus === "Refund"
                      }
                    >
                      {isLoading ? "Submitting..." : "Submit"}
                    </Button>
                  </div>
                </div>
              </div>
            </form>
            {/* <hr /> */}
            <div className="mt-2">
              {/* <CoursePayment /> */}
              <CourseAdmin />
            </div>
          </div>
        </div>
      </div>
      <CancelModal
        handleClose={handleClose}
        handleResume={handleResume}
        showModal={showModal}
        toggleModal={toggleModal}
      />
    </>
  );
}
