import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import delete1 from "../homework/assets/images/delete.png";
import axiosInstance from "../../axiosInterceptor";

const CourseEnquiry = () => {
  const [courseEnquiries, setCourseEnquiries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const perPage = 10;

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        `admin/courseEnquiry/getCourseEnquiry`,
        {
          params: {
            perPage: perPage,
            pageNo: page,
          },
        }
      );
      const { data, totalPages } = response?.data;
      setCourseEnquiries(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/courseEnquiry/deleteCourseEnquiry/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <div className="my-2">
          <div className="row mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="page-title">Course Inquiry </h4>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              {loading ? (
                <div className="text-center mt-4">
                  <p>Loading...</p>
                </div>
              ) : (
                <Table
                  bordered
                  responsive
                  className="mt-4 text-center"
                  style={{ fontSize: "0.8rem" }}
                >
                  <thead>
                    <tr>
                      <th>Sr. No</th>
                      <th>Email Id</th>
                      <th>WhatsApp No.</th>
                      <th>Course Name</th>
                      <th>Category Name</th>
                      <th>University Name</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseEnquiries?.length > 0 ? (
                      courseEnquiries.map((courseEnquiry, index) => {
                        const serialNumber =
                          (currentPage - 1) * perPage + index + 1;
                        return (
                          <tr key={index}>
                            <th scope="row">{serialNumber}</th>
                            <td>{courseEnquiry.email}</td>
                            <td>{courseEnquiry.wpNumber}</td>
                            <td>{courseEnquiry?.courseName}</td>
                            <td>{courseEnquiry?.courseType}</td>
                            <td>{courseEnquiry?.universityName}</td>

                            <td className="d-flex justify-content-center">
                              <div
                                className={`${Styles.delete_button_code} me-2 mx-2`}
                              >
                                <Button
                                  className={`${Styles.delete_button_code}`}
                                  onClick={() =>
                                    handleDelete(courseEnquiry._id)
                                  }
                                >
                                  <img
                                    src={delete1}
                                    alt="error"
                                    className={`${Styles.delete_icon}`}
                                  />
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="7" className="text-center">
                          No data found.
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
              <div className="float-end">
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>

                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((page) => {
                    if (
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                    ) {
                      return (
                        <PaginationItem
                          key={page}
                          active={page === currentPage}
                        >
                          <PaginationLink onClick={() => handlePageClick(page)}>
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                    if (
                      (page === currentPage - 2 || page === currentPage + 2) &&
                      totalPages > 5
                    ) {
                      return (
                        <PaginationItem key={`ellipsis-${page}`} disabled>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                      );
                    }
                    return null;
                  })}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this inquiry?</ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseEnquiry;
