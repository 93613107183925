import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import CreatableSelect from 'react-select/creatable';

const AddUniversities = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [countryError, setCountryError] = useState(false);
  const [universityErrors, setUniversityErrors] = useState([false]);
  const [countryList, setCountryList] = useState([]);
  const [countryOptions, setCountryOptions] = useState([]);


  const [data, setData] = useState({
    countryName: editData?.countryName || "",
    universityNames: editData
      ? [
        {
          universityName: editData.universityName,
          universityUrl: editData.universityUrl,
          isActive: editData.isActive ?? true,
        },
      ]
      : [{ universityName: "", universityUrl: "", isActive: true }],
  });

  const fetchData = async () => {
    try {
      const response = await axiosInstance.post(`admin/universities/getAllCountryByUniversity`);
      const countries = response.data.data;
      setCountryList(countries);

      const options = countries.map((countryName) => ({
        value: countryName,
        label: countryName,
      }));
      setCountryOptions(options);
    } catch (error) {
      console.error("Error fetching countries:", error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchData();
  }, []);


  useEffect(() => {
    fetchData();
  }, []);



  useEffect(() => {
    if (editData?.countryName) {
      const initialOption = {
        value: editData.countryName,
        label: editData.countryName,
      };
      handleChange(initialOption);
    }
  }, [editData]);

  const handleChange = (selectedOption) => {
    setData({ ...data, countryName: selectedOption ? selectedOption.value : "" });
    setCountryError(false);
  };
  const handleUniversityChange = (index, field, value) => {
    const newUniversityNames = [...data.universityNames];
    const apiField = field === "name" ? "universityName" :
      field === "url" ? "universityUrl" : field;
    newUniversityNames[index][apiField] = value;
    setData({ ...data, universityNames: newUniversityNames });

    if (field === "name") {
      const newUniversityErrors = [...universityErrors];
      newUniversityErrors[index] = false;
      setUniversityErrors(newUniversityErrors);
    }
  };

  const handleToggleChange = (index) => {
    const newUniversityNames = [...data.universityNames];
    newUniversityNames[index].isActive = !newUniversityNames[index].isActive;
    setData({ ...data, universityNames: newUniversityNames });
  };

  const addUniversityField = () => {
    setData({
      ...data,
      universityNames: [...data.universityNames, { universityName: "", universityUrl: "", isActive: true }]
    });
    setUniversityErrors([...universityErrors, false]);
  };

  const removeUniversityField = (index) => {
    const newUniversityNames = data.universityNames.filter((_, i) => i !== index);
    setData({ ...data, universityNames: newUniversityNames });

    const newUniversityErrors = universityErrors.filter((_, i) => i !== index);
    setUniversityErrors(newUniversityErrors);
  };

  const navigator = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const hasCountryError = !data.countryName;
    const hasUniversityErrors = data.universityNames.map((university) => !university.universityName);

    setCountryError(hasCountryError);
    setUniversityErrors(hasUniversityErrors);

    if (hasCountryError || hasUniversityErrors.some((error) => error)) {
      return;
    }

    try {
      let response;
      const requestData = {
        countryName: data.countryName,
        universitiesData: data.universityNames
      };

      if (editData) {
        response = await axiosInstance.put(
          `admin/universities/updateUniversity/${editData._id}`,
          requestData
        );
      } else {
        response = await axiosInstance.post(
          `admin/universities/createUniversity`,
          requestData
        );
      }

      if (response.status === 200 || response.status === 201) {
        toast.success(response.data.message);
        navigator("/universities");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Something went wrong. Please try again!!"
      );
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add University</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="8">
                <form className="ps-5 pe-5 pt-3" onSubmit={handleSubmit}>
                  <div className="form-group row mb-4">
                    <Label for="countryName" className="col-sm-4 col-form-label">
                      Country Name
                    </Label>
                    <div className="col-sm-8">
                      <CreatableSelect
                        id="countryName"
                        name="countryName"
                        options={countryOptions}
                        value={countryOptions.find((option) => option.value === data.countryName) || null}
                        onChange={handleChange}
                        onCreateOption={(newOption) => {
                          const newOptionObject = { value: newOption, label: newOption };
                          setCountryOptions((prevOptions) => [...prevOptions, newOptionObject]);
                          handleChange(newOptionObject);
                        }}
                        isDisabled={!!editData}
                        isClearable
                        isSearchable
                        placeholder="Select a country..."
                        noOptionsMessage={({ inputValue }) =>
                          inputValue ? "No countries found" : "Type to search countries"
                        }
                        classNamePrefix="select"
                      />

                      {countryError && formSubmitted && (
                        <FormFeedback className="d-block">
                          Country name is required
                        </FormFeedback>
                      )}
                    </div>
                  </div>
                  {data.universityNames.map((university, index) => (

                    <div className="form-group row mb-4" key={index}>
                      <Label
                        for={`universityName${index}`}
                        className="col-sm-4 col-form-label"
                      >
                        University Name
                      </Label>
                      <div className="col-sm-8 d-flex align-items-start">
                        <div className="w-100">
                          <Input
                            type="text"
                            className="form-control"
                            id={`universityName${index}`}
                            placeholder="Enter university name"
                            name={`universityName${index}`}
                            invalid={universityErrors[index] && formSubmitted}
                            value={university.universityName}
                            onChange={(e) =>
                              handleUniversityChange(index, "name", e.target.value)
                            }
                          />
                          <FormFeedback>
                            University name is required
                          </FormFeedback>
                          <Input
                            type="text"
                            className="form-control mt-2"
                            id={`universityUrl${index}`}
                            placeholder="Enter university URL"
                            name={`universityUrl${index}`}
                            value={university.universityUrl}
                            onChange={(e) =>
                              handleUniversityChange(index, "url", e.target.value)
                            }
                          />
                          <FormGroup switch className="mt-2">
                            <Input
                              type="switch"
                              id={`isActive${index}`}
                              name={`isActive${index}`}
                              checked={university.isActive}
                              onChange={() => handleToggleChange(index)}
                              className={`${Styles.form_check_input}`}
                            />
                            <Label check className="mx-1">
                              {university.isActive ? "Active" : "Deactive"}
                            </Label>
                          </FormGroup>
                        </div>

                        {!editData && (
                          <Button
                            type="button"
                            className="ms-2"
                            onClick={() => addUniversityField()}
                          >
                            +
                          </Button>
                        )}
                        {data.universityNames.length > 1 && (
                          <Button
                            type="button"
                            className="ms-2"
                            onClick={() => removeUniversityField(index)}
                          >
                            -
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}

                  <div className="form-group row mb-4">
                    <Label for="inputPassword" className="col-sm-4 col-form-label">
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">Save</Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/universities">
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddUniversities;