import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Table,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import delete1 from "../homework/assets/images/delete.png";
import axiosInstance from "../../axiosInterceptor";
import Styles from "../homework/assets/Form.module.css";

const CourseFAQs = () => {
  const { courseId } = useParams();
  const [faqs, setFaqs] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [loading, setLoading] = useState(true);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const perPage = 10;
  const navigator = useNavigate();

  const fetchCourseFaqs = async (page = 1) => {
    try {
      const response = await axiosInstance.get(
        `admin/courseFaqs/getFaqByCourse?pageNo=${page}&perPage=${perPage}`
      );
      if (response?.data?.isSuccess) {
        const faqData = response.data.data || [];
        setFaqs(faqData);
        setTotalPages(response.data.totalPages || 0);
        setCurrentPage(response.data.currentPageNo || page);
      } else {
        setFaqs([]);
        setTotalPages(0);
        toast.error(response?.data?.message || "Failed to fetch FAQs");
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCourseFaqs();
  }, []);


  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchCourseFaqs(page);
    }
  };

  const handleDelete = (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/courseFaqs/deleteCourseFaq/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchCourseFaqs();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <div className="my-2">
          <div className="row mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="page-title">Course FAQs</h4>
              </div>
              <div>
                {/* <Link to="/courses" className="mx-2">
                  <Button type="submit">Back To Courses</Button>
                </Link> */}
                <Link to={`/add-course-faqs`}>
                  <Button className="action-button add-from-new">
                    <img
                      src={pluscircle}
                      alt="plus"
                      width={20}
                      className="ADD_code"
                    />
                    <span> Add </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              <Table
                bordered
                responsive
                className="mt-4 text-center"
                style={{ fontSize: "0.8rem" }}
              >
                <thead>
                  <tr>
                    <th>#</th>
                    <th style={{ width: "35%" }}>Question</th>
                    <th style={{ width: "35%" }}>Answer</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {loading ? (
                    <tr>
                      <td colSpan="3" className="text-center">
                        Loading...
                      </td>
                    </tr>
                  ) : faqs.length > 0 ? (
                    faqs.map((faq, index) => (
                      <tr key={index}>
                        <th scope="row">{index + 1}</th>
                        <td className="text-start">
                          {faq.question.length > 75
                            ? `${faq.question.substring(0, 75)}...`
                            : faq.question}
                        </td>
                        <td className="text-start">
                          {faq.answer.length > 75
                            ? `${faq.answer.substring(0, 75)}...`
                            : faq.answer}
                        </td>
                        <td className="d-flex justify-content-center">
                          <div className={`${Styles.edit_code}`}>
                            <Button
                              className={`${Styles.edit_code}`}
                              onClick={() => {
                                navigator(`/add-course-faqs`, {
                                  state: faq,
                                });
                              }}
                            >
                              <img src={grp713} alt="error" width={20} />
                              Edit
                            </Button>
                          </div>
                          <div
                            className={`${Styles.delete_button_code} me-2 mx-2`}
                          >
                            <Button
                              className={`${Styles.delete_button_code}`}
                              onClick={() => handleDelete(faq._id)}
                            >
                              <img
                                src={delete1}
                                alt="error"
                                className={`${Styles.delete_icon}`}
                              />
                              Delete
                            </Button>
                          </div>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="4" className="text-center">
                        No FAQs found.
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>

              {faqs.length > 0 && (
                <div className="float-end mt-3">
                  <Pagination>
                    <PaginationItem disabled={currentPage === 1}>
                      <PaginationLink
                        previous
                        onClick={() => handlePageClick(currentPage - 1)}
                      />
                    </PaginationItem>
                    {Array.from(
                      { length: totalPages },
                      (_, index) => index + 1
                    ).map((page) => {
                      if (
                        page === 1 ||
                        page === totalPages ||
                        Math.abs(currentPage - page) <= 1
                      ) {
                        return (
                          <PaginationItem
                            key={page}
                            active={page === currentPage}
                          >
                            <PaginationLink onClick={() => handlePageClick(page)}>
                              {page}
                            </PaginationLink>
                          </PaginationItem>
                        );
                      }
                      if (
                        (page === currentPage - 2 || page === currentPage + 2) &&
                        totalPages > 5
                      ) {
                        return (
                          <PaginationItem key={`ellipsis-${page}`} disabled>
                            <PaginationLink>...</PaginationLink>
                          </PaginationItem>
                        );
                      }
                      return null;
                    })}
                    <PaginationItem disabled={currentPage === totalPages}>
                      <PaginationLink
                        next
                        onClick={() => handlePageClick(currentPage + 1)}
                      />
                    </PaginationItem>
                  </Pagination>
                </div>
              )}

            </CardBody>
          </Card>
        </div>
      </div >
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this FAQ?</ModalBody>
        <ModalFooter>
          <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
            No
          </Button>
          <Button type="cancel" className="me-4" onClick={confirmDelete}>
            Yes
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CourseFAQs;
