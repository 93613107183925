import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { FaSearch } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import delete1 from "../homework/assets/images/delete.png";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";

const LiveSessionPrice = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [liveSessionPriceData, setLiveSessionPriceData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const navigator = useNavigate();
  const perPage = 10;
  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `admin/liveSessionPrice/getAllPrice`,
        {
          search: searchTerm,
          perPage: 10,
          pageNo: page,
        }
      );

      const { data, totalPages } = response.data;
      setLiveSessionPriceData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const handleDelete = async (id) => {
    setDeleteItemId(id);
    setModalOpen(true);
  };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/liveSessionPrice/deletePrice/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <>
      <div>
        <div className="my-2">
          <div className="row mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="page-title">Live Session Price</h4>
              </div>
              <div>
                <Link to="/add-live-session-price">
                  <Button className="action-button add-from-new">
                    <img
                      src={pluscircle}
                      alt="plus"
                      width={20}
                      className="ADD_code"
                    />
                    <span className=""> Add</span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              <Row className="Extra_padding">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Search.."
                    name="searchTerm"
                    className="border_none_icon"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text wallte"
                      onClick={() => fetchData()}
                    >
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Row>
              {loading ? (
                <div className="text-center mt-4">
                  <p>Loading...</p>
                </div>
              ) : liveSessionPriceData?.length > 0 ? (
                <div className="table-responsive">
                  <Table
                    bordered
                    responsive
                    className="mt-4 text-center"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>From Minute</th>
                        <th>To Minute</th>
                        <th>Per Minute Price</th>
                        <th>Updated At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {liveSessionPriceData?.map((code, index) => {
                        const serialNumber =
                          (currentPage - 1) * perPage + index + 1;
                        return (
                          <tr key={index}>
                            <th scope="row">{serialNumber}</th>
                            <td>{code.fromMinute}</td>
                            <td>{code.toMinute}</td>
                            <td>${code.studentPrice?.toFixed(2)}</td>
                            <td>{dateFormat(code.updatedAt)}</td>
                            <td className="d-flex justify-content-center">
                              <div className={`${Styles.edit_code}`}>
                                <Button
                                  className={`${Styles.edit_code}`}
                                  onClick={() => {
                                    navigator("/add-live-session-price", {
                                      state: code,
                                    });
                                  }}
                                >
                                  <img
                                    src={grp713}
                                    alt="edit"
                                    className=""
                                    srcSet=""
                                    width={20}
                                  />
                                  Edit
                                </Button>
                              </div>
                              <div
                                className={`${Styles.delete_button_code} me-2 mx-2`}
                              >
                                <Button
                                  className={`${Styles.delete_button_code}`}
                                  onClick={() => handleDelete(code._id)}
                                >
                                  <img
                                    src={delete1}
                                    alt="delete"
                                    className={`${Styles.delete_icon}`}
                                    srcSet=""
                                  />
                                  Delete
                                </Button>
                              </div>
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </div>
              ) : (
                <div className="text-center mt-4">
                  <p>No data found.</p>
                </div>
              )}
              <div className="float-end">
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>
                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((page) => {
                    if (
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                    ) {
                      return (
                        <PaginationItem
                          key={page}
                          active={page === currentPage}
                        >
                          <PaginationLink onClick={() => handlePageClick(page)}>
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                    if (
                      (page === currentPage - 2 || page === currentPage + 2) &&
                      totalPages > 5
                    ) {
                      return (
                        <PaginationItem key={`ellipsis-${page}`} disabled>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                      );
                    }
                    return null;
                  })}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>
          Are you sure you want to delete this live session price?
        </ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LiveSessionPrice;
