import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";

const AddCourseFAQs = () => {
  const location = useLocation();
  const editData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();
  const { courseId } = useParams();

  const [faqs, setFaqs] = useState([
    {
      question: editData?.question || "",
      answer: editData?.answer || "",
    },
  ]);

  useEffect(() => {
    if (editData?.faqs) {
      setFaqs(editData.faqs);
    }
  }, [editData]);

  const handleChange = (index, e) => {
    const { name, value } = e.target;
    const newFaqs = [...faqs];
    newFaqs[index][name] = value;
    setFaqs(newFaqs);
  };

  const handleAddFaq = () => {
    setFaqs([...faqs, { question: "", answer: "" }]);
  };

  const handleRemoveFaq = (index) => {
    const newFaqs = faqs.filter((_, i) => i !== index);
    setFaqs(newFaqs);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const hasEmptyFields = faqs.some(
      (faq) => !faq.question.trim() || !faq.answer.trim()
    );

    if (hasEmptyFields) {
      return;
    }

    try {
      const response = await axiosInstance.post(
        `admin/courseFaqs/addCourseFaq`,
        { faqs }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator(`/course-faqs`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);

    const hasEmptyFields = faqs.some(
      (faq) => !faq.question.trim() || !faq.answer.trim()
    );

    if (hasEmptyFields) {
      return;
    }

    try {
      const response = await axiosInstance.put(
        `admin/courseFaqs/updateCourseFaq/${editData._id}`,
        { faqs }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator(`/course-faqs`);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">
              {editData?._id ? "Update Course FAQs" : "Add Course FAQs"}
            </h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={editData?._id ? handleUpdate : handleSubmit}
                >
                  {faqs?.map((faq, index) => (
                    <div key={index} className="row mb-4">
                      <div className="col-sm-6">
                        <div className="form-group mb-2">
                          <Label
                            for={`question-${index}`}
                            className="col-form-label"
                          >
                            Question
                          </Label>
                          <Input
                            type="textarea"
                            id={`question-${index}`}
                            placeholder="Enter your question"
                            name="question"
                            value={faq.question}
                            invalid={formSubmitted && !faq.question.trim()}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <FormFeedback>Question is required</FormFeedback>
                        </div>
                      </div>
                      <div className="col-sm-6">
                        <div className="form-group mb-2">
                          <Label
                            for={`answer-${index}`}
                            className="col-form-label"
                          >
                            Answer
                          </Label>
                          <Input
                            type="textarea"
                            id={`answer-${index}`}
                            placeholder="Enter your answer"
                            name="answer"
                            value={faq.answer}
                            invalid={formSubmitted && !faq.answer.trim()}
                            onChange={(e) => handleChange(index, e)}
                          />
                          <FormFeedback>Answer is required</FormFeedback>
                        </div>
                      </div>
                      <div className="col-sm-12 d-flex justify-content-end">
                        {faqs.length > 1 && (
                          <Button
                            type="button"
                            color="danger"
                            onClick={() => handleRemoveFaq(index)}
                          >
                            -
                          </Button>
                        )}
                        {!editData && (
                          <Button
                            type="button"
                            color="primary"
                            onClick={handleAddFaq}
                            className="ms-2"
                          >
                            +
                          </Button>
                        )}
                      </div>
                    </div>
                  ))}
                  <div className="form-group row mb-4">
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {editData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to={`/course-faqs`}>
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddCourseFAQs;
