import React, { useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  FormFeedback,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { ImageLink } from "../../constants";
import CommonEditor from "./CommonEditor";

const AddSubject = () => {
  const location = useLocation();
  const subjectData = location.state;
  const [formSubmitted, setFormSubmitted] = useState(false);
  const navigator = useNavigate();
  const [data, setData] = useState({
    questionSubject: subjectData?.questionSubject || "",
    image: subjectData?.image || "",
    subjectUrl: subjectData?.subjectUrl || "",
    description: subjectData?.description || "",
    showInHeader: subjectData?.showInHeader ?? true,
    showInFooter: subjectData?.showInFooter ?? true,
    isActive: subjectData?.isActive ?? true,
  });

  const handleChange = (e) => {
    const { name, files, type, checked } = e.target;
    if (name === "image") {
      setData({ ...data, [name]: files.length > 0 ? files[0] : null });
    } else if (type === "checkbox" || type === "switch") {
      setData({ ...data, [name]: checked });
    } else {
      setData({
        ...data,
        [name]: e.target.value,
      });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.questionSubject || !data.image) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("questionSubject", data.questionSubject);
      formData.append("image", data.image);
      formData.append("subjectUrl", data.subjectUrl);
      formData.append("description", data.description);
      formData.append("showInHeader", data.showInHeader);
      formData.append("showInFooter", data.showInFooter);
      formData.append("isActive", data.isActive);
      const response = await axiosInstance.post(
        `admin/subject/createsubject`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/subject");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong");
    }
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    setFormSubmitted(true);
    if (!data.questionSubject) {
      return;
    }
    try {
      const formData = new FormData();
      formData.append("questionSubject", data.questionSubject);
      formData.append("subjectUrl", data.subjectUrl);
      formData.append("description", data.description);
      formData.append("showInHeader", data.showInHeader);
      formData.append("showInFooter", data.showInFooter);
      formData.append("isActive", data.isActive);

      if (data.image instanceof File) {
        formData.append("image", data.image);
      }

      const response = await axiosInstance.put(
        `admin/subject/updateSubject/${subjectData?._id}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        navigator("/subject");
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      console.error("Update error:", error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  return (
    <div>
      <div className="mt-4 mb-4">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Add Subject</h4>
          </div>
        </div>
        <Card className="mt-4">
          <CardBody>
            <Row>
              <Col md="12">
                <form
                  className="ps-5 pe-5 pt-3"
                  onSubmit={subjectData?._id ? handleUpdate : handleSubmit}
                >
                  <div className="row form-group">
                    <div className="col-sm-6 mb-4">
                      <Label for="inputPassword" className="col-form-label">
                        Subject Name
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="inputPassword"
                        placeholder="Enter your subject name "
                        invalid={
                          !subjectData && !data.questionSubject && formSubmitted
                        }
                        name="questionSubject"
                        value={data.questionSubject}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Subject name is required</FormFeedback>
                    </div>
                    <div className="col-sm-6 mb-4">
                      <Label
                        for="subjectUrl"
                        className="col-sm-4 col-form-label"
                      >
                        Subject Url
                      </Label>
                      <Input
                        type="text"
                        className="form-control"
                        id="subjectUrl"
                        placeholder="Enter your subject url"
                        invalid={!data.subjectUrl && formSubmitted}
                        name="subjectUrl"
                        value={data.subjectUrl}
                        onChange={(e) => handleChange(e)}
                      />
                    </div>
                    <div className="col-sm-4 mb-4">
                      <Label for="img" className="col-form-label">
                        Image
                      </Label>
                      <Input
                        type="file"
                        id="img"
                        name="image"
                        invalid={!subjectData && !data.image && formSubmitted}
                        onChange={(e) => handleChange(e)}
                      />
                      <FormFeedback>Subject image is required</FormFeedback>
                      {data.image && (
                        <div className="border w-100 h-75 text-center mt-2">
                          {data.image instanceof File ? (
                            <img
                              src={URL.createObjectURL(data.image)}
                              alt="Preview"
                              className="mt-4"
                              style={{ width: "25%" }}
                            />
                          ) : (
                            <img
                              src={ImageLink(data.image)}
                              alt="Preview"
                              className="mt-4"
                              style={{ width: "25%" }}
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className="col-sm-8 mb-4">
                      <Label for="description" className="col-form-label">
                        Description
                      </Label>
                      <CommonEditor
                        value={data.description}
                        placeholder="Enter content ..."
                        onChange={(value) =>
                          handleChange({
                            target: { name: "description", value },
                          })
                        }
                      />
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-2">
                    <Label
                      for="showInHeader"
                      className="col-sm-4 col-form-label"
                    >
                      Show in Header
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          id="showInHeader"
                          name="showInHeader"
                          checked={data.showInHeader}
                          onChange={handleChange}
                          className={`${Styles.form_check_input}`}
                        />
                        <Label check className="mx-1">
                          {data.showInHeader ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4 mt-2">
                    <Label
                      for="showInFooter"
                      className="col-sm-4 col-form-label"
                    >
                      Show in Footer
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          id="showInFooter"
                          name="showInFooter"
                          checked={data.showInFooter}
                          onChange={handleChange}
                          className={`${Styles.form_check_input}`}
                        />
                        <Label check className="mx-1">
                          {data.showInFooter ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <br />
                  <div className="form-group row mb-4 mt-4">
                    <Label for="isActive" className="col-sm-4 col-form-label">
                      isActive
                    </Label>
                    <div className="col-sm-8">
                      <FormGroup switch>
                        <Input
                          type="switch"
                          id="isActive"
                          name="isActive"
                          checked={data.isActive}
                          onChange={handleChange}
                          className={`${Styles.form_check_input}`}
                        />
                        <Label check className="mx-1">
                          {data.isActive ? "Active" : "Deactive"}
                        </Label>
                      </FormGroup>
                    </div>
                  </div>
                  <div className="form-group row mb-4">
                    <Label
                      for="inputPassword"
                      className="col-sm-4 col-form-label"
                    >
                      <b className="ms-2"></b>
                    </Label>
                    <div className="col-sm-7 d-flex">
                      <div className={`${Styles.submit_button}`}>
                        <Button type="submit">
                          {subjectData?._id ? "Update" : "Save"}
                        </Button>
                      </div>
                      <div className={`${Styles.cancel_button} me-4 mx-2`}>
                        <Link to="/subject">
                          {" "}
                          <Button className={`${Styles.cancel_button}`}>
                            Cancel
                          </Button>
                        </Link>
                      </div>
                    </div>
                  </div>
                </form>
              </Col>
            </Row>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default AddSubject;
