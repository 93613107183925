import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import pluscircle from "../homework/assets/images/plus circle_.png";
import grp713 from "../homework/assets/images/Group 713.png";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import { FaSearch } from "react-icons/fa";

const CompletehelpStep = () => {
  const [loading, setLoading] = useState(false);
  const [helpData, setHelpData] = useState([]);
  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [subCategoryData, setSubCategoryData] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const perPage = 10;
  const navigator = useNavigate();

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `admin/completeHelpStep/getAllHelpSteps`,
        {
          searchTerm: searchTerm,
          perPage: perPage,
          pageNo: page,
        }
      );
      const { data, totalPages } = response.data;
      setHelpData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  const fetchSubCatData = async (page = 1) => {
    try {
      const response = await axiosInstance.get(
        `admin/subcategory/getAllSubcategory`
      );
      const { data } = response.data;
      setSubCategoryData(data);
    } catch (error) {
      console.error(error);
      toast.error("Something went wrong. Please try again!!");
    }
  };

  useEffect(() => {
    fetchSubCatData();
  }, []);

  return (
    <>
      <div>
        <div className="my-2">
          <div className="row mb-2">
            <div className="d-flex justify-content-between">
              <div>
                <h4 className="page-title">Complete Help Step</h4>
              </div>
              <div>
                <Link to="/addhelpstep">
                  <Button className="action-button add-from-new">
                    <img
                      src={pluscircle}
                      alt="plus"
                      width={20}
                      className="ADD_code"
                    />
                    <span className=""> Add </span>
                  </Button>
                </Link>
              </div>
            </div>
          </div>

          <Card>
            <CardBody>
              <Row className="Extra_padding">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Search.."
                    name="searchTerm"
                    className="border_none_icon"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span
                      className="input-group-text wallte"
                      onClick={() => fetchData()}
                    >
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Row>
              {loading ? (
                <div className="text-center mt-4">
                  <p>Loading...</p>
                </div>
              ) : (
                <div className="table-responsive">
                  <Table
                    bordered
                    responsive
                    className="mt-4 text-center"
                    style={{ fontSize: "0.8rem" }}
                  >
                    <thead>
                      <tr>
                        <th>Sr. No</th>
                        <th>Main Title</th>
                        <th>Sub Category</th>
                        <th>Updated At</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {helpData?.length > 0 ? (
                      <tbody>
                        {helpData?.map((data, index) => {
                          const matchData = subCategoryData.find(
                            (subcat) => subcat._id === data.subCategoryId
                          );
                          const serialNumber =
                            (currentPage - 1) * perPage + index + 1;
                          return (
                            <tr>
                              <th>{serialNumber}</th>
                              <td>{data.mainTitle.slice(0, 20)}...</td>
                              <td>{matchData?.name}</td>
                              <td>{dateFormat(data.updatedAt)}</td>
                              <td className="d-flex justify-content-center">
                                <div className={`${Styles.edit_code}`}>
                                  <Button
                                    className={`${Styles.edit_code}`}
                                    onClick={() => {
                                      navigator("/addhelpstep", {
                                        state: data,
                                      });
                                    }}
                                  >
                                    <img
                                      src={grp713}
                                      alt="error"
                                      className=""
                                      srcSet=""
                                      width={20}
                                    />
                                    Edit
                                  </Button>
                                </div>
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    ) : (
                      <div className="text-center mt-4">
                        <p>No data found.</p>
                      </div>
                    )}
                  </Table>
                </div>
              )}
              <div className="float-end">
                <Pagination>
                  <PaginationItem disabled={currentPage === 1}>
                    <PaginationLink
                      previous
                      onClick={() => handlePageClick(currentPage - 1)}
                    />
                  </PaginationItem>
                  {Array.from(
                    { length: totalPages },
                    (_, index) => index + 1
                  ).map((page) => {
                    if (
                      page === 1 ||
                      page === totalPages ||
                      Math.abs(currentPage - page) <= 1
                    ) {
                      return (
                        <PaginationItem
                          key={page}
                          active={page === currentPage}
                        >
                          <PaginationLink onClick={() => handlePageClick(page)}>
                            {page}
                          </PaginationLink>
                        </PaginationItem>
                      );
                    }
                    if (
                      (page === currentPage - 2 || page === currentPage + 2) &&
                      totalPages > 5
                    ) {
                      return (
                        <PaginationItem key={`ellipsis-${page}`} disabled>
                          <PaginationLink>...</PaginationLink>
                        </PaginationItem>
                      );
                    }
                    return null;
                  })}
                  <PaginationItem disabled={currentPage === totalPages}>
                    <PaginationLink
                      next
                      onClick={() => handlePageClick(currentPage + 1)}
                    />
                  </PaginationItem>
                </Pagination>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </>
  );
};

export default CompletehelpStep;
