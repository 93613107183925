import React, { useEffect, useState } from "react";
import { FaSearch } from "react-icons/fa";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import axiosInstance from "../../axiosInterceptor";
import { dateFormat } from "../../dateFormat";
import { useNavigate, useParams } from "react-router-dom";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

const Studentdetails = () => {
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [studentsdetelis, setStudentsDetelis] = useState([]);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  const navigator = useNavigate();
  const { studentId } = useParams();

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);
      const response = await axiosInstance.post(
        `admin/student/getByStudent/${studentId}`,
        {
          search: searchTerm,
          fromDate: fromDate,
          toDate: toDate,
          perPage: 10,
          pageNo: page,
          orderId: searchTerm,
        }
      );
      if (response.data.isSuccess) {
        setStudentsDetelis(response.data);
        setTotalPages(response.data.totalPages);
        setCurrentPage(page);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, fromDate, toDate]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  return (
    <div className="my-2">
      <h4 className="page-title">Student details</h4>
      <div className="student-deletlis">
        <Card>
          <CardBody>
            <Row>
              <Col md="6">
                <DateRangePicker
                  className="w-100"
                  // value={[new Date(fromDate), new Date(toDate)]}
                  onChange={(value) => {
                    if (value && value[0]) {
                      setFromDate(value[0].toISOString().split("T")[0]);
                      setToDate(
                        value[1] ? value[1].toISOString().split("T")[0] : ""
                      );
                    }
                  }}
                  onClean={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                />
              </Col>
              <Col md="6">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Order Id"
                    name="fname"
                    className="border_none_icon"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text wallte">
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : studentsdetelis?.data?.length > 0 ? (
              <div className="table-responsive">
                <Table
                  bordered
                  className="mt-4"
                  responsive
                  style={{ fontSize: "0.8rem" }}
                >
                  <thead>
                    <tr className="font-small-head">
                      <th>Date</th>
                      <th className="text-center">Order Id</th>
                      <th className="text-center">Type</th>
                      <th className="text-center">Subject</th>
                      <th className="text-center">Status</th>
                      <th className="text-center">Price</th>
                      <th className="text-center">Payment Status</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentsdetelis?.data?.map((students) => (
                      <tr key={students._id}>
                        <td>
                          {dateFormat(students?.updatedAt)}
                          <br />
                          {/* {students?.student?.email} */}
                        </td>
                        <td className="text-center">{students?.displayId}</td>
                        <td className="text-center">
                          {students?.subCategoryId?.name}
                        </td>
                        <td className="text-center">
                          {students?.subjectId?.questionSubject}
                        </td>
                        <td className="text-center">
                          {students?.questionStatus}
                        </td>
                        <td className="text-center">
                          ${students?.totalPrice?.toFixed(2)}
                        </td>
                        <td className="text-center">
                          {students?.studentPaymentStatus}
                        </td>
                        <td className="text-center">
                          <Button
                            className="action-button"
                            onClick={() => {
                              if (students.paymentSection === "HomeworkHelp") {
                                navigator("/homework-form", {
                                  state: students,
                                });
                              } else if (
                                students.paymentSection === "AssignmentHelp"
                              ) {
                                navigator("/assignment-form", {
                                  state: students,
                                });
                              } else if (
                                students.paymentSection === "LiveSessionHelp"
                              ) {
                                navigator("/live-session-chat", {
                                  state: students,
                                });
                              }
                            }}
                          >
                            View
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}

            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {Array.from(
                  { length: totalPages },
                  (_, index) => index + 1
                ).map((page) => {
                  if (
                    page === 1 ||
                    page === totalPages ||
                    Math.abs(currentPage - page) <= 1 
                  ) {
                    return (
                      <PaginationItem key={page} active={page === currentPage}>
                        <PaginationLink onClick={() => handlePageClick(page)}>
                          {page}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  }
                  if (
                    (page === currentPage - 2 || page === currentPage + 2) &&
                    totalPages > 5
                  ) {
                    return (
                      <PaginationItem key={`ellipsis-${page}`} disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    );
                  }
                  return null;
                })}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default Studentdetails;
