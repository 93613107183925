import React, { useState } from 'react';
import { Button, Card, CardBody, Col, FormFeedback, FormGroup, Input, Label, Row } from "reactstrap";
import Styles from "../homework/assets/Form.module.css";
import { toast } from "react-toastify";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";

const AddCourseReviews = () => {
    const location = useLocation();
    const editData = location.state;
    const [formSubmitted, setFormSubmitted] = useState(false);
    const navigator = useNavigate();
    const { courseId } = useParams();


    const [data, setData] = useState({
        name: editData?.name || "",
        ratings: editData?.ratings || "",
        review: editData?.review || "",
        date: editData?.date || ""
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setData({
            ...data,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        if (!data.name || !data.ratings || !data.review || !data.date) {
            return;
        }

        try {

            const response = await axiosInstance.post(
                `admin/courseReview/addCourseReview/${courseId}`,
                data
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/courses/course-review/${courseId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again!!");
        }
    };

    const handleUpdate = async (e) => {
        e.preventDefault();
        setFormSubmitted(true);

        if (!data.name || !data.ratings || !data.review || !data.date) {
            return;
        }

        try {
            const response = await axiosInstance.put(
                `admin/courseReview/updateCourseReview/${editData._id}`,
                data
            );
            if (response.status === 200) {
                toast.success(response.data.message);
                navigator(`/courses/course-review/${courseId}`);
            } else {
                toast.error(response.data.message);
            }
        } catch (error) {
            console.error(error);
            toast.error("Something went wrong. Please try again!!");
        }
    };

    return (
        <div>
            <div className="mt-4 mb-4">
                <div className="row">
                    <div className="col-sm-6">
                        <h4 className="page-title">Add Course Review</h4>
                    </div>
                </div>
                <Card className="mt-4">
                    <CardBody>
                        <Row>
                            <Col md="12">
                                <form
                                    className="ps-5 pe-5 pt-3"
                                    onSubmit={editData?._id ? handleUpdate : handleSubmit}
                                >
                                    <div className="row mb-4">
                                        <div className="col-sm-6">
                                            <div className="form-group mb-2">
                                                <Label for="name" className="col-form-label">
                                                    Name
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="name"
                                                    placeholder="Enter reviewer name"
                                                    name="name"
                                                    invalid={!data.name && formSubmitted}
                                                    defaultValue={data.name}
                                                    onChange={handleChange}
                                                />
                                                <FormFeedback>Name is required</FormFeedback>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group mb-2">
                                                <Label for="ratings" className="col-form-label">
                                                    Ratings
                                                </Label>
                                                <Input
                                                    type="number"
                                                    className="form-control"
                                                    id="ratings"
                                                    placeholder="Enter ratings (1-5)"
                                                    name="ratings"
                                                    min="1"
                                                    max="5"
                                                    invalid={!data.ratings && formSubmitted}
                                                    defaultValue={data.ratings}
                                                    onChange={handleChange}
                                                />
                                                <FormFeedback>Ratings are required</FormFeedback>
                                            </div>
                                        </div>

                                        <div className="col-sm-12">
                                            <div className="form-group mb-2">
                                                <Label for="review" className="col-form-label">
                                                    Review
                                                </Label>
                                                <Input
                                                    type="textarea"
                                                    className="form-control"
                                                    id="review"
                                                    placeholder="Enter review"
                                                    name="review"
                                                    invalid={!data.review && formSubmitted}
                                                    defaultValue={data.review}
                                                    onChange={handleChange}
                                                    rows="4"
                                                />
                                                <FormFeedback>Review is required</FormFeedback>
                                            </div>
                                        </div>

                                        <div className="col-sm-6">
                                            <div className="form-group mb-2">
                                                <Label for="date" className="col-form-label">
                                                    Date
                                                </Label>
                                                <Input
                                                    type="text"
                                                    className="form-control"
                                                    id="date"
                                                    name="date"
                                                    invalid={!data.date && formSubmitted}
                                                    defaultValue={data.date}
                                                    onChange={handleChange}
                                                />
                                                <FormFeedback>Date is required</FormFeedback>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="form-group row mb-4">
                                        <div className="col-sm-7 d-flex">
                                            <div className={`${Styles.submit_button}`}>
                                                <Button type="submit">
                                                    {editData?._id ? "Update" : "Save"}
                                                </Button>
                                            </div>
                                            <div className={`${Styles.cancel_button} me-4 mx-2`}>
                                                <Link to={`/courses/course-review/${courseId}`}>
                                                    <Button className={`${Styles.cancel_button}`}>
                                                        Cancel
                                                    </Button>
                                                </Link>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </div>
    );
};

export default AddCourseReviews;