import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Input,
  InputGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
} from "reactstrap";
import { FaSearch } from "react-icons/fa";
import Styles from "../homework/assets/Form.module.css";
import { useNavigate } from "react-router-dom";
import axiosInstance from "../../axiosInterceptor";
import { toast } from "react-toastify";
import { dateFormat } from "../../dateFormat";
import { DateRangePicker } from "rsuite";
import "rsuite/DateRangePicker/styles/index.css";

const Courselist = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [activeButton, setActiveButton] = useState("Paid");
  const [loading, setLoading] = useState(false);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [listData, setListData] = useState([]);

  const [totalPages, setTotalPages] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [deleteItemId, setDeleteItemId] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const statusData = listData?.filter(
    (item) => item?.studentPaymentStatus === activeButton
  );

  const navigator = useNavigate();
  const handleButtonClick = (buttonType) => {
    setActiveButton(buttonType);
  };

  const fetchData = async (page = 1) => {
    try {
      setLoading(true);

      const response = await axiosInstance.post(
        `admin/purchaseCourse/getCourse?page=${page}`,
        {
          search: searchTerm,
          fromDate: fromDate,
          toDate: toDate,
          status: activeButton,
          perPage: 10,
          pageNo: page,
        }
      );

      const { data, totalPages } = response.data;
      setListData(data);
      setTotalPages(totalPages);
      setCurrentPage(page);
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [searchTerm, fromDate, toDate, activeButton]);

  const handlePageClick = (page) => {
    if (page >= 1 && page <= totalPages) {
      fetchData(page);
    }
  };

  //   const handleDelete = async (id) => {
  //     setDeleteItemId(id);
  //     setModalOpen(true);
  //   };

  const confirmDelete = async () => {
    try {
      const response = await axiosInstance.delete(
        `admin/purchaseCourse/deleteCourse/${deleteItemId}`
      );
      if (response.status === 200) {
        toast.success(response.data.message);
        fetchData();
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setDeleteItemId(null);
      setModalOpen(false);
    }
  };

  return (
    <div>
      <div className="my-2">
        <div className="row">
          <div className="col-sm-6">
            <h4 className="page-title">Course help List</h4>
          </div>
          <div className="col-sm-6 text-end">
            {/* <Button
              className={`mx-2 ${activeButton === "Pending" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Pending" ? "#5956E9" : "#fff",
                color: activeButton === "Pending" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Pending")}
            >
              Unpaid
            </Button> */}
            <Button
              className={`mx-2 ${activeButton === "Paid" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Paid" ? "#5956E9" : "#fff",
                color: activeButton === "Paid" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Paid")}
            >
              Paid
            </Button>
            <Button
              className={`mx-2 ${activeButton === "Completed" ? "active" : ""}`}
              style={{
                backgroundColor:
                  activeButton === "Completed" ? "#5956E9" : "#fff",
                color: activeButton === "Completed" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Completed")}
            >
              Completed
            </Button>
            {/* <Button
              className={`mx-2 ${activeButton === "Refund" ? "active" : ""}`}
              style={{
                backgroundColor: activeButton === "Refund" ? "#5956E9" : "#fff",
                color: activeButton === "Refund" ? "#fff" : "#000",
              }}
              onClick={() => handleButtonClick("Refund")}
            >
              Refund
            </Button> */}
          </div>
        </div>
        <Card className="mt-2">
          <CardBody>
            <Row>
              <Col md="6">
                <DateRangePicker
                  className="w-100"
                  onChange={(value) => {
                    if (value && value[0]) {
                      setFromDate(value[0].toISOString().split("T")[0]);
                      setToDate(
                        value[1] ? value[1].toISOString().split("T")[0] : ""
                      );
                    }
                  }}
                  onClean={() => {
                    setFromDate(null);
                    setToDate(null);
                  }}
                />
              </Col>
              <Col md="6">
                <InputGroup className="search_input_icon">
                  <Input
                    type="text"
                    id="fname"
                    placeholder="Order ID"
                    name="fname"
                    className="border_none_icon"
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    onKeyPress={(e) => {
                      if (e.key === "Enter") {
                        fetchData();
                      }
                    }}
                  />
                  <div className="input-group-append">
                    <span className="input-group-text wallte">
                      <FaSearch />
                    </span>
                  </div>
                </InputGroup>
              </Col>
            </Row>
            {loading ? (
              <div className="text-center mt-4">
                <p>Loading...</p>
              </div>
            ) : statusData.length > 0 ? (
              <div className="table-responsive">
                <Table
                  bordered
                  responsive
                  className="mt-4"
                  style={{ fontSize: "0.8rem" }}
                >
                  <thead>
                    <tr className="text-center font-small-head">
                      <th>Date & Time</th>
                      <th>Order ID</th>
                      <th>Type</th>
                      <th>Payment</th>
                      <th>Status</th>
                      <th>Earning</th>
                      {/* <th>Refund</th> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody style={{ fontSize: "0.8rem" }}>
                    {statusData?.map((item, index) => (
                      <tr key={index}>
                        <td>
                          {dateFormat(item?.createdAt)}
                          <br />
                          {item?.studentId?.email}
                        </td>
                        <td className="text-center">{item.displayId}</td>
                        <td className="text-center">{item?.courseInnerType}</td>
                        <td className="text-center">{item.paymentMethod}</td>
                        <td className="text-center">
                          {item?.studentPaymentStatus}
                        </td>
                        <td className="studentamoun text-center">
                          ${item.studentAmount?.toFixed(2)}
                        </td>
                        {/* <td className="studentamoun text-center">
                          ${item.refundAmount?.toFixed(2)}
                        </td> */}
                        <td className="d-flex justify-content-center">
                          <Button
                            className="action-button"
                            onClick={() => {
                              navigator("/course-form", { state: item });
                            }}
                          >
                            View
                          </Button>
                          {/* <div className="me-2 mx-2">
                            <Button
                              className="delete_ques"
                              onClick={() => handleDelete(item._id)}
                            >
                              Delete
                            </Button>
                          </div> */}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            ) : (
              <div className="text-center mt-4">
                <p>No data found.</p>
              </div>
            )}
            <div className="float-end">
              <Pagination>
                <PaginationItem disabled={currentPage === 1}>
                  <PaginationLink
                    previous
                    onClick={() => handlePageClick(currentPage - 1)}
                  />
                </PaginationItem>
                {[...Array(totalPages)].map((_, i) => {
                  if (
                    i === 0 ||
                    i === totalPages - 1 ||
                    (i >= currentPage - 1 && i <= currentPage + 1) ||
                    (currentPage <= 3 && i <= 3) ||
                    (currentPage >= totalPages - 2 && i >= totalPages - 4)
                  ) {
                    return (
                      <PaginationItem key={i} active={i + 1 === currentPage}>
                        <PaginationLink onClick={() => handlePageClick(i + 1)}>
                          {i + 1}
                        </PaginationLink>
                      </PaginationItem>
                    );
                  } else if (
                    (i === 1 && currentPage > 4) ||
                    (i === totalPages - 2 && currentPage < totalPages - 3)
                  ) {
                    return (
                      <PaginationItem key={i} disabled>
                        <PaginationLink>...</PaginationLink>
                      </PaginationItem>
                    );
                  }
                  return null;
                })}
                <PaginationItem disabled={currentPage === totalPages}>
                  <PaginationLink
                    next
                    onClick={() => handlePageClick(currentPage + 1)}
                  />
                </PaginationItem>
              </Pagination>
            </div>
          </CardBody>
        </Card>
      </div>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        style={{ top: "33%" }}
      >
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>
          Confirm Delete
        </ModalHeader>
        <ModalBody>Are you sure you want to delete this order?</ModalBody>
        <ModalFooter>
          <div className={`${Styles.button_color}`}>
            <Button type="cancel" onClick={() => setModalOpen(!modalOpen)}>
              No
            </Button>
          </div>
          <div className={`${Styles.cancel_button} me-4`}>
            <Button
              type="cancel"
              className={`${Styles.cancel_button}`}
              onClick={confirmDelete}
            >
              Yes
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Courselist;
