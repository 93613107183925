import React, { useEffect, useRef, useState } from "react";
import Styles from "../homework/assets/Form.module.css";
import { Button, Input, Label } from "reactstrap";
import { Link, useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import axiosInstance from "../../axiosInterceptor";

const CourseAdmin = () => {
  const location = useLocation();
  const editData = location.state;  
  const formRef = useRef(null);
  const [answerEditing, setAnswerEditing] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const baseURL = process.env.REACT_APP_FILE_BASE_URL;

  useEffect(() => {
    if (editData?.answerFile) {
      const initialFiles = editData.answerFile.map((file) => ({
        name: file.split("/").pop(),
        url: `${baseURL}${file}`,
        isNew: false,
      }));
      setSelectedFiles(initialFiles);
    }
  }, [editData, baseURL]);

  const handleFileChange = (event) => {
    const files = Array.from(event.target.files);
    const fileDetails = files.map((file) => ({
      name: file.name,
      file: file,
      isNew: true,
    }));
    setSelectedFiles((prevFiles) => [...prevFiles, ...fileDetails]);
  };

  const handleRemoveFile = async (index) => {
    const fileToRemove = selectedFiles[index];
    const newFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(newFiles);

    if (!fileToRemove.isNew) {
      const requestData = {
        paymentSection: "courseHelp",
        fieldName: "answerFile",
        questionId: editData?._id,
        fileName: fileToRemove.url.split("/").pop(),
      };

      try {
        const response = await axiosInstance.post(
          "admin/deleteFile",
          requestData
        );
        if (response.data.isSuccess) {
          // toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      } catch (error) {
        toast.error("Something went wrong. Please try again!!");
      }
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();

    try {
      setIsLoading(true);
      const formData = new FormData();
      const filePromises = selectedFiles.map(async (fileDetail) => {
        if (fileDetail.isNew) {
          formData.append("answerFile", fileDetail.file);
        } else {
          const response = await fetch(fileDetail.url);
          const blob = await response.blob();
          const fileName = fileDetail.url.split("/").pop();
          formData.append("answerFile", new File([blob], fileName));
        }
      });

      await Promise.all(filePromises);

      const response = await axiosInstance.put(
        `admin/purchaseCourse/updateAnswer/${editData._id}`,
        formData
      );

      if (response.data.isSuccess) {
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again!!");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="pt-5">
      <div className="d-flex justify-content-between">
        <div className="ps-5 pt-2">
          <span className={`${Styles.student_corner_text}`}>For Student</span>
        </div>
      </div>
      <form ref={formRef} className="pt-3" onSubmit={handleUpdate}>
        <div className="ps-5 pe-5">
          <div className="pt-3">
            <div className="form-group row mb-4">
              <Label for="inputPassword" className="col-sm-3 col-form-label">
                Course File<b className="ms-2">:</b>
              </Label>
              <div
                className="col-sm-6"
                style={{
                  backgroundColor: "#F5F5F5",
                  height: "auto",
                  padding: "0px",
                }}
              >
                <Input
                  type="file"
                  name="assignment_file_answer"
                  disabled={editData?.studentPaymentStatus === "Refund"}
                  multiple
                  onChange={handleFileChange}
                />
                <div className="d-flex align-items-center mt-4">
                  {selectedFiles?.length > 0 && (
                    <ol>
                      {selectedFiles.map((fileDetail, index) => (
                        <li className="mb-2 answer-pdf" key={index}>
                          {fileDetail.isNew ? (
                            <span>{fileDetail.name}</span>
                          ) : (
                            <Link
                              className="text-decoration-none"
                              to={fileDetail.url}
                              target="_blank"
                            >
                              {fileDetail.name}
                            </Link>
                          )}
                          <button
                            className="mx-2 answer-pdf-box"
                            type="button"
                            onClick={() => handleRemoveFile(index)}
                          >
                            X
                          </button>
                        </li>
                      ))}
                    </ol>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div
          className={`d-flex justify-content-around ${Styles.quesion_color} p-3`}
        >
          <div className="d-flex">
            <div className={`${Styles.cancel_button} me-4`}>
              <Button
                className={`${Styles.cancel_button}`}
                onClick={() => setAnswerEditing(false)}
                disabled={editData?.studentPaymentStatus === "Refund"}
              >
                Cancel
              </Button>
            </div>
            <div className={`${Styles.submit_button}`}>
              <Button
                type="submit"
                disabled={editData?.studentPaymentStatus === "Refund"}
              >
                {isLoading ? "Submitting..." : "Submit"}
              </Button>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
};

export default CourseAdmin;
